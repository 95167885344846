.congrats {
  background:
    url('images/congrats/bg-top.png') right -220px top -280px no-repeat,
    url('images/congrats/bg-bottom.jpg') center bottom no-repeat;
  background-size:
    808px 629px,
    1402px 515px;
  background-color: var(--grey0);
  background-blend-mode: multiply;

  @media (max-width: 800px) {
    background:
      url('images/congrats/bg-top.png') center top -120px no-repeat,
      url('images/congrats/bg-bottom.jpg') center bottom no-repeat;
    background-size:
      338px 275px,
      496px 200px;
    background-color: var(--grey0);
    background-blend-mode: multiply;
  }

  &__wrap {
    height: 100%;
    width: 90%;
    max-width: 850px;
    position: relative;
    margin: 0px auto;
    padding: 60px 0px 300px 0px;

    @media (max-width: 800px) {
      padding: 170px 0px 100px 0px;
    }
  }

  &-top {
    &__title {
      font-family: var(--lato);
      font-size: 40px;
      font-weight: 700;
      color: var(--grey90);
      text-shadow:
        -2px -2px #fff,
        -2px -1.5px #fff,
        -2px -1px #fff,
        -2px -0.5px #fff,
        -2px 0px #fff,
        -2px 0.5px #fff,
        -2px 1px #fff,
        -2px 1.5px #fff,
        -2px 2px #fff,
        -1.5px 2px #fff,
        -1px 2px #fff,
        -0.5px 2px #fff,
        0px 2px #fff,
        0.5px 2px #fff,
        1px 2px #fff,
        1.5px 2px #fff,
        2px 2px #fff,
        2px 1.5px #fff,
        2px 1px #fff,
        2px 0.5px #fff,
        2px 0px #fff,
        2px -0.5px #fff,
        2px -1px #fff,
        2px -1.5px #fff,
        2px -2px #fff,
        1.5px -2px #fff,
        1px -2px #fff,
        0.5px -2px #fff,
        0px -2px #fff,
        -0.5px -2px #fff,
        -1px -2px #fff,
        -1.5px -2px #fff,
        0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-bottom: 35px;

      @media (max-width: 800px) {
        font-size: 20px;
        text-shadow: none;
        margin-bottom: 20px;
        text-align: center;
      }
    }

    &__info {
      font-size: 14px;
      font-family: var(--poppins);
      margin-bottom: 50px;

      @media (max-width: 800px) {
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }

  &-block {
    margin-bottom: 70px;

    @media (max-width: 800px) {
      margin-bottom: 50px;
    }

    &__title {
      font-family: var(--lato);
      font-weight: 700;
      font-size: 24px;
      font-style: italic;
      color: var(--primary-color);
      margin-bottom: 25px;

      @media (max-width: 800px) {
        font-size: 20px;
        text-align: center;
      }

      &-list {
        font-family: var(--lato);
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;

        @media (max-width: 800px) {
          font-size: 18px;
          text-align: center;
        }
      }
    }

    .block-list {
      display: flex;
      gap: 50px;

      @media (max-width: 800px) {
        gap: 25px;
      }

      @media (max-width: 650px) {
        gap: 50px;
      }

      @media (max-width: 650px) {
        flex-direction: column;
      }

      &__item {
        flex: 1;
      }

      .list-item {
        &__title {
          font-family: var(--lato);
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 20px;

          @media (max-width: 800px) {
            font-size: 18px;
            text-align: center;
          }
        }

        &__image {
          display: block;
          height: 240px;
          width: 100%;
          object-fit: cover;
          box-shadow:
            0px 8px 16px rgba(58, 58, 68, 0.12),
            0px 16px 32px rgba(90, 91, 106, 0.12);
          margin-bottom: 40px;
          border-radius: 8px;

          @media (max-width: 650px) {
            height: auto;
            margin-bottom: 25px;
          }
        }

        &__text {
          font-family: var(--poppins);
          font-size: 14px;
          margin-bottom: 20px;
          line-height: 1.4;

          &:last-child {
            margin-bottom: 0px;
          }

          b {
            font-weight: 700;
          }

          i {
            font-style: italic;
          }

          a {
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }

        &__list {
          font-family: var(--poppins);
          font-size: 14px;
          margin-bottom: 20px;
          line-height: 1.4;
          list-style: initial;

          li {
            margin-left: 15px;
          }
        }
      }
    }

    &_single {
      .block-list {
        .list-item {
          &__image {
            margin-bottom: 0px;
          }
        }
      }
    }

    &_help {
      .block-list {
        .list-item {
          &__title {
            @media (max-width: 800px) {
              text-align: left;
            }
          }

          &__text {
            &:last-child {
              @media (max-width: 800px) {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  &-dashboard {
    text-align: center;
    margin-bottom: 70px;

    @media (max-width: 800px) {
      margin-bottom: 50px;
    }

    &__text {
      font-family: var(--poppins);
      font-size: 14px;
      margin-bottom: 10px;

      a {
        text-decoration: underline;
        font-weight: 700;
      }
    }

    &__link {
      margin-top: 30px;
      display: inline-block;
      text-align: center;
      height: 65px;
      line-height: 65px;
      border-radius: 8px;
      max-width: 465px;
      font-size: 18px;
      font-family: var(--inter);
      font-weight: 600;

      @media (max-width: 650px) {
        line-height: 55px;
        height: 55px;
      }
    }
  }
}
