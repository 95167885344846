.product-selector {
  display: flex;

  @media (max-width: 600px) {
    width: 100%;
    justify-content: space-around;
  }

  .product-selector__product {
    border: none;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;
    color: #000;

    @media (max-width: 600px) {
      padding: 6px 10px;
      font-size: 14px;
    }

    @media (max-width: 480px) {
      padding: 6px 8px;
      font-size: 12px;
    }

    @media (max-width: 400px) {
      padding: 6px 5px;
      font-size: 12px;
    }

    &.new,
    &.pending-actions:not(.active) {
      cursor: pointer;
      &::after {
        content: '';
        height: 0.5em;
        width: 0.5em;
        background-color: var(--primary-color);
        border-radius: 50%;
        display: inline-block;
        margin-top: -10px;
        margin-left: 0.25em;
        margin-right: -0.5em;

        @media (max-width: 600px) {
          position: absolute;
          margin: 0px;
          top: 5px;
          right: 0px;
        }
      }
      &:hover {
        background-color: transparent;
      }
    }
    &.active {
      background-color: var(--primary-color);
      color: #fff;
    }
    &.product-king {
      --primary-color: #0066a6;
    }
    &.product-lover {
      --primary-color: #d13d38;
    }
    &.product-warrior {
      --primary-color: #169f61;
    }
    &.product-magician {
      --primary-color: #69009a;
    }
  }
}
