@import '../../../../css/colors';

.progress_tracker__upnext {
  background-color: #f9fdff;
  border-bottom: 1px solid #cecece;

  &__annual_lab {
    .radio-option {
      margin-top: 0;
      min-height: auto;
    }
    .bp3-control-indicator {
      margin-left: 0 !important;
    }
  }

  &__reason {
    border-radius: 8px;
    border: 3px solid var(--primary-color);
    box-shadow:
      0px 4px 8px rgba(58, 58, 68, 0.12),
      0px 8px 16px rgba(90, 91, 106, 0.12);
    padding: 24px;
    margin-top: 26px;

    background: #fff url(../../../../../images/progress-tracker/king.png) no-repeat right;
    background-position-y: 10px;

    .progress_tracker.selectedProduct-lover & {
      background-image: url(../../../../../images/progress-tracker/lover.svg);
    }

    .progress_tracker.selectedProduct-warrior & {
      background: none;
    }

    .progress_tracker.selectedProduct-magician & {
      background: none;
    }

    &__title {
      color: $gray_3;
      padding: 20px 0;
      font-weight: 300;

      b {
        font-weight: 700;
      }

      ul {
        list-style: initial;

        li {
          margin: 0px 0px 10px 20px;
        }
      }
    }

    .heading {
      color: var(--primary-color);
      font-size: 20px;
      font-weight: bold;
    }
  }

  &__progressbar {
    display: flex;
    align-items: center;

    .line {
      width: 80%;
      height: 8px;
      background-color: #ededf0;
      position: relative;
      margin-right: 13px;

      @media (max-width: 479px) {
        margin-right: 7px;
      }

      &__inner {
        position: absolute;
        background-color: #37833b;
        height: 8px;
      }
    }

    .value {
      @media (max-width: 479px) {
        font-size: 12px;
      }
    }

    &__flex {
      display: flex;
      gap: 48px;

      @media (max-width: 479px) {
        gap: 15px;
      }

      &.elation {
        @media (max-width: 479px) {
          gap: 20px;
          flex-direction: column;
        }
      }

      & > div {
        flex: 1 1 50%;
      }

      .w50 {
        @media (max-width: 479px) {
          width: 100%;
        }
      }

      .i_registered {
        background-color: var(--primary-color);
      }
    }
  }

  .new-pictures {
    margin-top: 30px;

    .content {
      margin-top: 15px;

      .flex {
        gap: 25px;

        button {
          flex: 50%;
        }
      }
    }

    .description {
      margin-bottom: 20px;
    }

    button {
      width: 40%;

      @media (max-width: 479px) {
        width: 100%;
      }
    }
  }

  .next-refill {
    margin-top: 24px;
    border: 2px solid var(--primary-color);
    box-shadow:
      0px 2.70504px 5.41007px rgba(58, 58, 68, 0.12),
      0px 5.41007px 10.8201px rgba(90, 91, 106, 0.12);
    border-radius: 6px;
    padding-bottom: 26px;

    .count {
      font-size: 38px;
      color: #fff;
      padding: 8px;
      background: var(--primary-color);
    }

    .title {
      text-transform: uppercase;
      font-size: 20px;
      color: var(--primary-color);
      font-weight: bold;
      margin-left: 16px;

      @media (max-width: 479px) {
        font-size: 16px;
      }
    }

    .content {
      padding: 24px 24px 0 24px;
    }

    .flex {
      border-bottom: 2px solid var(--primary-color);
      align-items: center;
    }

    button {
      width: 40%;

      @media (max-width: 479px) {
        width: 100%;
        margin: 0;
      }
    }
  }

  &__no_notifications {
    background-color: #f8f8f8;
    color: #292a31;
    margin-top: 26px;
    text-align: center;
    padding: 15px 0;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
  }
}

.progress_tracker__content.reason {
  display: flex;
  gap: 74px;

  @media (max-width: 479px) {
    flex-direction: column;
  }

  & > div:first-child {
    flex-basis: 700px;

    @media (max-width: 479px) {
      flex-basis: auto;
    }
  }

  & > div:last-child {
    flex: 1;
  }
}

.notifications-popup-menu {
  padding: 0 !important;

  .bp3-menu-item {
    padding: 12px;

    &.selected {
      border-left: 5px solid var(--primary-color);
    }
  }
}

.text12 {
  font-size: 12px;
  font-weight: 300;
}

.registeredButton {
  background-color: #43a147 !important;
}

.maintain {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  & > div:first-child {
    color: #444;
    font-size: 16px;
    font-weight: 800;
  }

  & > div:last-child {
    height: 1px;
    flex: 1 0 0;
    background: #444;
  }
}

.update-questionnaire {
  margin-bottom: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 4px;
    color: var(--gray-3, #444);

    &__title {
      font-size: 16px;
      font-weight: 800;
      font-family: var(--lato);
      line-height: 114%;
    }

    &::after {
      content: '';
      display: block;
      flex: 1;
      height: 1px;
      background-color: var(--gray-3, #444);
    }
  }

  &__content {
    font-weight: 300;
    font-family: var(--poppins);
    font-size: 12px;
  }
}
