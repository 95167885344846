.heading_with_icon,
.heading_with_icon--up {
  display: flex;
  justify-content: left;
}
.heading_with_icon {
  align-items: center;
  > .svg-icon {
    color: var(--primary-color);
    display: flex;
    > span,
    > div {
      display: flex;
    }
  }
}
.heading_with_icon--up {
  align-items: flex-start;
}
.heading_with_icon__text {
  margin-left: 8px;
  margin-right: 8px;
  font-family: Lato;
}
