@import '../../../../css/colors';

.progress_tracker__faq {
  &__questions {
    margin-top: 40px;
  }
  &__questions .question {
    border-bottom: 1px solid #d1d1d1;
    padding: 24px 0;
    p {
      margin: 0.75em auto;
    }
    sup {
      font-size: smaller;
    }
    i {
      font-style: italic;
    }
    ul.dots {
      list-style: disc;
      padding-left: 1.5em;
    }
    a:hover {
      color: var(--primary-color);
    }
  }
}
