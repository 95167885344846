.progress_tracker {
  &.selectedProduct-lover {
    --primary-color: #d13d38;
    --primary-lighten-color: #e38b88; // lighten 40.7
    --primary-lightest-color: #fdf8f8; // lighten 96.5%
    --primary-darken-color: #b42e29; // darken 16.25
  }
  &.selectedProduct-warrior {
    --primary-color: #169f61;
    --primary-lighten-color: #1abc73;
    --primary-lightest-color: #1ed984;
    --primary-darken-color: #128651;
  }
  &.selectedProduct-magician {
    --primary-color: #69009a;
    --primary-lighten-color: #8100be;
    --primary-lightest-color: #9a00e1;
    --primary-darken-color: #580081;
  }
}
