.login {
  grid-area: main;
  height: 100%;
  background: #000;

  &__wrap {
    max-width: 1520px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0px auto;

    @media (max-width: 800px) {
      flex-direction: column;
      width: 90%;
    }
  }

  .default-button {
    height: 46px;
    font-size: 14px;
    letter-spacing: initial;
    font-family: var(--inter);
    font-weight: 500;
    border-radius: 4px;
    background: #4583bd;

    &:active:not(:disabled),
    &.active {
      background-color: var(--primary-darken-color);
    }
    &:hover:not(:active):not(&.active):not(:disabled),
    &.hover {
      background-color: #62a0da !important;
    }
  }

  .text-field {
    text-align: center;
    height: 46px;
    border-radius: 4px;
    font-family: var(--lato);
    padding: 0px 50px;

    &:disabled {
      opacity: 1;
    }
  }

  &-field {
    margin-bottom: 15px;
  }

  &-image {
    background: url(images/bg-sign-in.png) center no-repeat;
    background-size: contain;
    height: 100%;
    width: 50%;

    @media (max-width: 800px) {
      height: 365px;
      width: 100%;
    }
  }

  &-form {
    width: 45%;
    color: #fff;
    margin-left: 5%;
    padding: 25px 0px;

    @media (max-width: 1500px) {
      width: 50%;
      margin-left: 0%;
    }

    @media (max-width: 1200px) {
      width: 47%;
      margin-left: 3%;
    }

    @media (max-width: 1000px) {
      width: 44%;
      margin-left: 6%;
    }

    @media (max-width: 800px) {
      width: 100%;
      margin: 0px;
      padding-top: 0px;
      margin-top: -70px;
    }

    &__wrap {
      max-width: 380px;

      @media (max-width: 800px) {
        margin: 0px auto;
      }
    }

    &__icon {
      width: 32px;
      margin-bottom: 5px;
    }

    &__title {
      font-weight: 700;
      font-size: 48px;
      font-family: var(--cormorant);
      margin-bottom: 10px;

      @media (max-width: 1000px) {
        font-size: 44px;
      }
    }

    &__info {
      font-size: 18px;
      font-weight: 300;
      font-family: var(--lato);
      line-height: 24px;
      margin-bottom: 25px;

      @media (max-width: 1000px) {
        font-size: 16px;
      }
    }
  }

  &-or {
    font-size: 18px;
    font-weight: 300;
    font-family: var(--lato);
    text-align: center;
    position: relative;
    margin: 20px 0px;

    span {
      background: #000;
      position: relative;
      padding: 0px 15px;
    }

    &:before {
      background: #434343;
      height: 1px;
      left: 0px;
      right: 0px;
      top: 50%;
      content: '';
      position: absolute;
    }
  }

  &-options {
    &__text {
      font-size: 12px;
      color: #c8c8c8;
      margin: 15px 0px;
      text-align: center;

      &-error {
        color: #ff3333;
      }

      a {
        color: #4583bd;
        text-decoration: underline;

        &:hover {
          color: #4583bd;
          text-decoration: underline;
        }
      }
    }
  }
}
