.slide-card-width {
  max-width: 462px !important;
}

.carousel {
  .slide {
    text-align: left;

    img {
      padding-top: 0;
      width: 100%;
      height: 204px;

      @media (max-width: 1140px) {
        height: 180px;
      }
    }
  }
}

.centered {
  .resources-card {
    &.card {
      background-color: transparent;
      border: 0;
      --tw-drop-shadow: drop-shadow(0 0 13px rgb(0 0 0 / 0.05)) drop-shadow(0 0 5px rgb(0 0 0 / 0.08));

      @media (max-width: 1140px) {
        margin: 20px;
      }
    }
  }
}

.new-theme {
  a {
    &.slide-button {
      color: white;
    }
  }
}

.slide-card-image {
  object-fit: cover;
}
