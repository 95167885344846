.pre-qualified-card {
  background: var(--color-white) url('images/congrats/bg-top.png') center top -120px no-repeat;
  background-size: 338px 210px;
  background-blend-mode: multiply;
}

.pre-qualified-step {
  background:
    url('images/congrats/bg-top.png') center top -120px no-repeat,
    url('images/congrats/bg-bottom.jpg') center bottom no-repeat;
  background-size:
    338px 275px,
    496px 200px;
  background-color: var(--grey0);
  background-blend-mode: multiply;

  &__wrap {
    height: 100%;
    width: 90%;
    max-width: 850px;
    position: relative;
    margin: 0px auto;
    padding: 170px 0px 100px 0px;
  }

  &-top {
    &__title {
      font-family: var(--lato);
      font-size: 20px;
      font-weight: 700;
      color: var(--grey90);
      text-shadow: none;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  &-block {
    margin-bottom: 50px;

    .block-list {
      display: flex;
      gap: 50px;
      flex-direction: column;

      &__item {
        flex: 1;
      }

      .list-item {
        &__text {
          font-family: var(--poppins);
          font-size: 14px;
          margin-bottom: 20px;
          line-height: 1.4;

          &:last-child {
            margin-bottom: 0px;
          }

          b {
            font-weight: 700;
          }

          i {
            font-style: italic;
          }

          a {
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }

    &_single {
      .block-list {
        .list-item {
          &__image {
            margin-bottom: 0px;
          }
        }
      }
    }
  }

  &-choose-plan {
    text-align: center;
    margin-bottom: 50px;

    &__link {
      margin-top: 30px;
      display: inline-block;
      text-align: center;
      height: 55px;
      line-height: 55px;
      border-radius: 8px;
      max-width: 465px;
      font-size: 18px;
      font-family: var(--inter);
      font-weight: 600;

      .default-button {
        text-transform: none !important;
        font-family: var(--inter);
        font-size: 16px;
        font-weight: 600;
        line-height: 19.36px;
      }
    }
  }
}
