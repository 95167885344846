@font-face {
  font-family: Cambon;
  font-weight: 400;
  src: url('app/../fonts/CambonBook/Cambon-Regular.ttf');
}
@font-face {
  font-family: Cambon;
  font-weight: 500;
  src: url('app/../fonts/CambonBook/Cambon-SemiBold.ttf');
}

@font-face {
  font-family: Lato;
  src:
    url('app/../fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('app/../fonts/Lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: Univers;
  font-weight: 400;
  src:
    url('app/../fonts/UniversLightNormal/Univers-Light-Normal.woff') format('woff'),
    url('app/../fonts/UniversLightNormal/Univers-Light-Normal.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: OpenSans;
  font-weight: 300;
  src: url('app/../fonts/Open_Sans/OpenSans-Light.ttf');
}
@font-face {
  font-family: OpenSans;
  font-weight: 400;
  src: url('app/../fonts/Open_Sans/OpenSans-Regular.ttf');
}
@font-face {
  font-family: OpenSans;
  font-weight: 600;
  src: url('app/../fonts/Open_Sans/OpenSans-SemiBold.ttf');
}
@font-face {
  font-family: OpenSans;
  font-weight: 700;
  src: url('app/../fonts/Open_Sans/OpenSans-Bold.ttf');
}
@font-face {
  font-family: Roboto;
  src: url('app/../fonts/Roboto/Roboto-Regular.ttf');
  font-display: swap;
}
