@import 'colors';

.center-align {
  text-align: center;
  input {
    text-align: center;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.height__full-screen {
  min-height: 100%;
}
.line-through {
  text-decoration: line-through;
}
.clickable {
  cursor: pointer;
}
.hover-underlined:hover {
  text-decoration: underline;
}

.hover-non-underlined {
  text-decoration: underline;

  &:hover {
    text-decoration: initial;
  }
}
.underlined {
  text-decoration: underline;
}
.font-size-inherit {
  font-size: inherit !important;
}
.font-weight-inherit {
  font-weight: inherit !important;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.gap10 {
  gap: 10px;
}
.flex1 {
  flex: 1;
}
.text-left {
  text-align: left;
}
.w50 {
  width: 50%;
}
.w33 {
  width: 33.33333333333333%;
}
.bgwhite {
  background-color: var(--white);
}
.pointer {
  cursor: pointer;
}
.hidden {
  visibility: hidden;
}
.display-none {
  display: none;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.z1 {
  z-index: 1;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}
.bold {
  font-weight: bold;
}

.overflow-scroll {
  overflow-y: scroll;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn2s {
  animation: fadein 2s;
}

.field-error {
  box-shadow:
    0px 8px 16px rgba(244, 67, 54, 0.16),
    0px 4px 8px rgba(156, 43, 35, 0.16);
}

.black {
  background-color: black !important;
}
.gold {
  color: var(--gold50);
}
.white {
  color: $white;
}

.pre-wrap {
  white-space: pre-wrap;
}

.freeform-ul {
  list-style-type: disc;
}

.steps-transition-wrapper {
  position: relative;
  z-index: 1;

  > * + * {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    min-width: 100%;
  }

  .transitioning {
    pointer-events: none;
  }
}

.hide-mobile {
  @media (max-width: 479px) {
    display: none !important;
  }
}
.hide-desktop {
  @media (min-width: 480px) {
    display: none !important;
  }
}

%visually-hidden {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  left: -9999px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  top: auto !important;
  width: 1px !important;
}

.ghost {
  @extend %visually-hidden;
}

%section-divider {
  &:before {
    content: '';
    width: 50px;
    height: 3px;
    border-radius: 3px;
    display: block;
    margin: 0 auto 30px;
  }
}

.section-divider--default {
  &:before {
    background-color: #cdcdcd;
  }

  @extend %section-divider;
}

.section-divider--primary {
  &:before {
    background-color: var(--primary-color);
  }

  @extend %section-divider;
}

.blue {
  color: $blue_3;
}

.color-black {
  color: $black;
}

.flex1 iframe {
  height: 100%;
  width: 100%;
  display: 'flex';
}
