@import '../../../css/colors';

.social-media {
  &__text {
    font-family: var(--inter);
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;

    a {
      text-decoration: underline;
      font-weight: 600;
    }
  }

  &__list {
    display: flex;
    justify-content: center;

    a:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__item {
    font-size: 28px;
    transition: color 0.2s;
    color: #656565;

    &:hover,
    &:focus {
      color: $blue_3;
    }
  }
}
