.plan_selection {
  .plan_box {
    border-radius: 8px;
    border: 1px solid var(--Default-Blue, #0066a6);
  }

  .plan_box_sub_header {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.plan_modal {
  max-width: 375px;
}
