@import '../../../../css/colors';

.centered.progress_tracker {
  width: 100% !important;
  max-width: 100% !important;
}

.progress_tracker__content {
  margin: 0 auto;
  padding: 26px 16px;
  width: 100%;

  @media (min-width: 480px) {
    padding: 60px 0;
    width: 1170px;
  }
  .gap50 {
    gap: 50px;
  }
}
.progress_tracker__header {
  width: 100% !important;
  border-bottom: 1px solid #cecece;
  &__steps_button {
    .default-button {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      svg {
        position: relative;
        top: 1px;
        path {
          fill: var(--primary-color);
        }
      }
    }
    @media (min-width: 480px) {
      display: none;
    }
  }
  &__modal {
    &__heading {
      margin-bottom: 18px;
      font-weight: bold;
    }
    .modal_flex {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;
      & > div:first-child {
        margin-bottom: 8px;
      }
      & > img:nth-child(4) {
        margin-top: 16px;
      }
      &.completed-on,
      &.in-progress {
        color: var(--primary-darken-color);
      }
    }
    @media (min-width: 480px) {
      display: none;
    }
  }
  &__name {
    color: var(--primary-color);
    font-weight: bold;
    font-size: 56px;
  }
  &__flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    @media (max-width: 479px) {
      flex-direction: column;
    }
  }
  &__right {
    .flex {
      @media (max-width: 479px) {
        justify-content: space-between;
      }
    }
    &__item {
      border-left: 1px solid #e1e1e3;
      padding: 0 24px;
      @media (max-width: 479px) {
        font-size: 14px;
        padding: 0 8px;
        margin-top: 20px;
      }
    }
  }
  &__item_value {
    color: var(--primary-darken-color);
    font-weight: bold;
    margin-top: 5px;
    text-transform: capitalize;
  }
  &__item_title {
    color: #4a4b57;
    @media (max-width: 479px) {
      font-size: 10px;
    }
  }
  &__doughnut {
    position: relative;
    svg {
      color: var(--primary-color);
    }
    &__value {
      position: absolute;
      top: 36%;
      left: 44%;
    }
    @media (max-width: 479px) {
      left: calc(50% - 56px);
      margin: 30px 0;
    }
  }
}

.progress_tracker__wizard {
  display: flex;
  justify-content: space-between;
  flex: 1;
  color: var(--primary-color);
  &__item.not_started {
    .progress_tracker__wizard__flex {
      margin-bottom: 18px;
    }
    p {
      color: #9696a0 !important;
    }
  }
  &__title {
    font-weight: bold;
    max-width: 90%;
    &.completed {
      text-decoration: line-through;
    }
  }
  &__status {
    font-size: 12px;
    margin-top: 7px;
    color: #4a4b57;
    max-width: 90%;
  }
  &__item:not(:last-child) {
    flex-grow: 1;
    flex-basis: 0;
  }
  &__item:last-child {
    .progress_tracker__wizard {
      &__title,
      &__status {
        max-width: 100%;
      }
    }
  }
  &__item__line {
    height: 1px;
    margin: 0 20px;
    background-color: #e1e1e3;
    width: 100%;
  }
  &__flex {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    svg {
      display: block;
    }
  }
}
.screen-compatible-width {
  @media (max-width: 1140px) {
    margin: 20px;
  }
}
