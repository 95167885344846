.intake {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  grid-template-areas: 'progress-bar' 'question-label' 'question';
}
@media (min-width: 480px) {
  .intake {
    height: auto;
  }
}
