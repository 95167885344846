.radio-option {
  min-height: 48px;
  // line-height: 48px;
  margin-top: 12px;
  background-color: white;
  &._view-module__raised___3v1Jd._view-module__checked___2AY8L {
    background-color: var(--primary-lightest-color);
    border-color: var(--primary-color);
  }
  &._view-module__raised___3v1Jd > label[class*='control'] span[class*='indicator'] {
    border-color: var(--primary-color);
    &::before {
      background-color: var(--primary-color);
      color: var(--primary-color);
      background-image: radial-gradient(var(--primary-color), var(--primary-color));
    }
  }
  > label > div {
    flex: 1;
  }
  &.no-borders {
    box-shadow: none;
    &._view-module__raised__h0rBJ._view-module__checked__Pib1C {
      border: 1px solid transparent;
      background-color: #fff;
    }
  }
  &.top-aligned .bp3-control-indicator {
    margin: 0 1.125rem auto 0 !important;
  }
  &._view-module__disabled__Tc8ed {
    opacity: 0.36;
    .titles {
      color: #000;
    }
    &:hover {
      background: none;
      box-shadow: none;
    }
  }
}
@media (min-width: 480px) {
  .radio-option {
    min-height: 56px;
    // line-height: 56px;
    margin-top: 16px;
  }
}

.updated-design-radio {
  box-shadow:
    0px 2px 4px rgba(58, 58, 68, 0.1),
    0px 4px 8px rgba(90, 91, 106, 0.1);
  font-family: var(--lato);
  padding: 0 16px;
  height: 56px;
  label.bp3-control.bp3-large {
    font-size: 18px;
    span.bp3-control-indicator {
      margin-left: 0;
    }
  }
}

.payments {
  .radio-option {
    min-height: 20px;
  }
}
