.snackbar-wrapper {
  display: flex;
  align-items: center;
  margin: 8px;
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1;
  transition:
    opacity 300ms,
    transform 300ms;
  pointer-events: none;

  &--top-left,
  &--top-center,
  &--top-right {
    top: 8px;
  }

  &--bottom-left,
  &--bottom-center,
  &--bottom-right {
    bottom: 8px;
  }

  &--top-left,
  &--bottom-left {
    justify-content: flex-start;
  }

  &--top-center,
  &--bottom-center {
    justify-content: center;
  }

  &--top-right,
  &--bottom-right {
    justify-content: flex-end;
  }
}

.snackbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  min-width: 334px;
  max-width: 672px;
  background-color: #333;
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.87);
  letter-spacing: 0.25px;
  line-height: 20px;
  text-align: left;

  @media (max-width: 480px), (max-width: 344px) {
    min-width: 100%;
  }

  &__message {
    flex-grow: 1;
    padding: 14px 16px;
    margin: 0;
    pointer-events: auto;

    a {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__close {
    flex-shrink: 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 36px;
    height: 36px;
    padding: 8px;
    margin: 0 8px 0 0;
    cursor: pointer;
    pointer-events: auto;
    -webkit-tap-highlight-color: transparent;

    // Reset button styles
    outline: none;
    background-color: transparent;
    border: none;

    // SVG icon properties
    font-size: 12px;
    color: rgba(255, 255, 255, 0.87);

    &:before {
      content: '';
      background-color: #fff;
      border-radius: 50%;
      opacity: 0;
      position: absolute;
      transition: opacity 120ms linear;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover:before {
      opacity: 0.08;
    }

    svg {
      width: 1em;
      height: 1em;
    }
  }

  // Themes
  &--info {
    background-color: #19191d;
  }

  &--success {
    background-color: #32ba7c;
  }

  &--error {
    background-color: #c9372d;
  }

  // Transitions
  &--enter,
  &--exit-active {
    opacity: 0;
  }

  &--enter-active {
    opacity: 1;
  }

  &--enter-top-left,
  &--enter-top-center,
  &--enter-top-right,
  &--exit-active-top-left,
  &--exit-active-top-center,
  &--exit-active-top-right {
    transform: translateY(-16px);
  }

  &--enter-bottom-left,
  &--enter-bottom-center,
  &--enter-bottom-right,
  &--exit-active-bottom-left,
  &--exit-active-bottom-center,
  &--exit-active-bottom-right {
    transform: translateY(16px);
  }

  &--enter-active-top-left,
  &--enter-active-top-center,
  &--enter-active-top-right,
  &--enter-active-bottom-left,
  &--enter-active-bottom-center,
  &--enter-active-bottom-right {
    transform: translateY(0px);
  }
}
