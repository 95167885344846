.manage_subscription {
  &.centered {
    max-width: 720px;
  }

  &_box {
    padding: 0px 10px;
  }

  &__columns {
    display: flex;
    gap: 20px;
    justify-content: space-between;

    @media (max-width: 480px) {
      flex-direction: column;
    }
  }

  &__column {
    &_btns {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 20px;

      button {
        text-transform: uppercase !important;
        font-size: 12px !important;
        letter-spacing: 1.5px !important;
        height: 45px;
      }

      @media (max-width: 480px) {
        width: 100%;
        margin-bottom: 20px;
        padding-top: 20px;
        border-top: 1px solid #d9d9d9;
      }
    }
  }

  &__info {
    background: #e8f6ff;
    padding: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.09px;
  }

  &__item {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0px;
    }

    &_king {
      .manage_subscription__title span {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: url('images/i-title-products-king.svg') center no-repeat;
          background-size: contain;
          width: 21px;
          height: 12px;
          left: -10px;
          top: -4px;
          transform: rotate(-30deg);
        }
      }
    }

    &_lover {
      .manage_subscription__title span {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: url('images/i-title-products-lover.svg') center no-repeat;
          background-size: contain;
          width: 14px;
          height: 14px;
          top: -7px;
          left: -3.5px;
        }
      }
    }

    &_warrior {
      .manage_subscription__title span {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          background: url('images/i-title-products-warrior.svg') center no-repeat;
          background-size: contain;
          width: 24px;
          height: 24px;
          top: 3px;
          left: 49px;
        }
      }
    }
  }

  &__back {
    border-radius: 8px;
    height: 65px;
    font-weight: 600;
    font-size: 18px;
  }

  &__cancel_modal {
    text-align: center;
    padding: 30px;
    background-color: #fff !important;
    width: 470px;
    &.update_subscription {
      text-align: left;
      max-width: 860px;
      form label {
        text-align: left;
        > div {
          margin-top: 20px;
        }
      }
    }
    &.justify {
      text-align: justify;
    }
    &.align-left {
      text-align: left;
    }
    &.supplement_info {
      padding: 20px;
      .bottle_mobile {
        float: right;
        width: 125px;
        display: none;
      }
      .info_table {
        width: 100%;
        margin-top: 0;
      }
      @media (max-width: 479px) {
        display: flex;
        flex-direction: row-reverse;
        max-width: 345px !important;

        .bottle_mobile {
          display: block;
        }
        .flex > div:first-child {
          display: none;
        }
        img {
          max-width: 100%;
        }
      }
      a {
        text-decoration: underline;
        font-weight: 600;
      }
      h3 {
        font-size: 16px;
      }
      h1 {
        line-height: 36px;
        margin-top: 0;
        color: #272833;
      }
      p {
        color: #414141;
        margin: 20px 0;
      }
      ._type-module__header__OMBUS {
        height: 0;
        margin: 0;
        span {
          margin: -10px -10px 0 0;
          display: block;
        }
      }
    }
    &.maximized {
      width: 720px;
      max-width: 720px;
    }

    div {
      color: #000 !important;
    }

    & > div > div {
      font-size: 18px;
    }

    &__content {
      border-top: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      padding: 40px 0;
      margin-bottom: 20px;

      img {
        margin: 30px 0px;
      }

      h2 {
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.0075em;

        span {
          position: relative;
        }
      }

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }

      &.cancel-success {
        border: none;
        padding: 0px 0px 20px 0px;
        margin: 0px;

        p {
          font-weight: 400;
        }
      }

      &.lover {
        h2 span {
          &:before {
            content: '';
            position: absolute;
            background: url('images/i-title-products-lover.svg') center no-repeat;
            background-size: contain;
            width: 14px;
            height: 14px;
            top: -7px;
            left: -3.5px;
          }
        }
      }

      &.king {
        h2 span {
          &:before {
            content: '';
            position: absolute;
            background: url('images/i-title-products-king.svg') center no-repeat;
            background-size: contain;
            width: 21px;
            height: 12px;
            left: -10px;
            top: -4px;
            transform: rotate(-30deg);
          }
        }
      }
    }

    &__buttons {
      font-size: 14px;
      gap: 10px;

      .dont {
        background-color: #0066a6;
        color: #fff;
      }

      button {
        padding-left: 0 !important;
        padding-right: 0 !important;
        flex: 1;
        text-transform: initial;
        letter-spacing: initial;
        font-size: 14px;
        font-family: var(--inter);
      }
    }
    .DayPicker-Day--outside,
    .DayPicker-Weekday {
      color: #8b9898 !important;
    }
    .DayPicker-Day--today {
      color: #d0021b !important;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
  }

  &__content {
    background: #ffffff;
    padding: 32px;
    border-bottom: 1px solid #e9e9e9;
    box-shadow:
      0px 2px 4px rgba(58, 58, 68, 0.1),
      0px 4px 8px rgba(90, 91, 106, 0.1);
    border-radius: 4px;

    &__friendly_name {
      font-size: 14px;
      color: #19191d;
    }

    &__contact_support {
      font-size: 12px;
    }

    button {
      padding-left: 15px !important;
      padding-right: 15px !important;

      &.reactivate {
        background-color: #32ba7c;
        border-color: #32ba7c;
        color: #fff;

        &:hover {
          background-color: #41cf8e;
          border-color: #41cf8e;
          color: #fff;
        }

        &:active {
          background-color: #27a16a;
          border-color: #27a16a;
          color: #fff;
        }
      }

      &.full {
        text-transform: initial;
        letter-spacing: initial;
        font-size: 14px;
        font-family: var(--inter);
      }
    }

    &__separator {
      height: 1px;
      background: #d9d9d9;
      margin: 20px 0px;

      @media (max-width: 480px) {
        display: none;
      }
    }

    &__status {
      display: flex;
      gap: 6px;
      align-items: center;
      font-size: 14px;
      margin-top: 8px;
    }

    &__status_circle {
      width: 10px;
      height: 10px;
      background-color: #32ba7c;
      border-radius: 5px;

      &.cancelled {
        background-color: #d13d38;
      }
      &.paused {
        background-color: #ffc700;
      }
    }

    .flex {
      gap: 20px;
      > div:first-child {
        width: 70%;

        @media (max-width: 480px) {
          width: 100%;
        }
      }
      & > p {
        font-size: 12px;
      }
    }
  }
}
