.product-header-container {
  .subtitle {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.0075em;
    text-align: left;
    padding-top: 20px;
  }

  .breadcrumbs {
    margin-bottom: 20px;
  }
}
