.onboarding-freeform {
  p {
    font-family: var(--poppins) !important;
  }
  .physician-card {
    display: flex;
    gap: 25px;
    align-items: center;
    &__image {
      img {
        width: 150px;
        height: 150px;
        border: 3.5px solid #ffffff;
        filter: drop-shadow(0px 3.46255px 25.9692px rgba(0, 0, 0, 0.2));
        border-radius: 150px;
        box-sizing: border-box;
      }
    }
    &__title {
      font-family: 'Cormorant Garamond', 'Times New Roman', Times, serif;
      margin-bottom: 6px;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
    }

    &__specialties,
    &__education,
    &__residency {
      // font-size: 12px;
      line-height: 18px;
      font-weight: 300;
    }
    &__specialties {
      font-weight: 500;
    }
  }
  p {
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: 20px;
    // font-size: 14px;
    font-family: var(--inter);
    text-align: justify;
  }

  .tip {
    border-radius: 8px;
    display: flex;
    padding: 24px;
    position: relative;
    background: #f0f0f0;
    gap: 16px;
    &:before {
      content: '';
      mask-image: url(images/Icon-Lab.svg);
      mask-repeat: no-repeat;
      mask-size: cover;
      mask-position: center;
      background-color: #e7e7e7;
      width: 75px;
      position: absolute;
      z-index: 0;
      left: 45%;
      top: 12%;
      height: 117px;
    }

    .tip__image {
      mask-image: url(images/Icon-Lab.svg);
      mask-repeat: no-repeat;
      mask-size: cover;
      mask-position: center;
      background-color: #0066a6;
      width: 48px;
      height: 48px;
      display: block;
    }

    .tip__info {
      font-family: var(--poppins);
      flex: 1;
      z-index: 1;

      .tip__title {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 16px;
      }

      .tip__text {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        text-align: justify;
        z-index: 1;
      }
    }
  }
  @media (max-width: 600px) {
    margin-left: 16px;
    margin-right: 16px;
    .physician-card__image {
      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
