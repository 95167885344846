.carousel {
  .carousel__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 1.5px solid #007bff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Optional shadow
    transition:
      transform 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    z-index: 999;

    &--left {
      left: 10px;
    }

    &--right {
      right: 10px;
    }
  }

  img {
    pointer-events: auto;
    width: auto;
  }

  .control-dots {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);

    .dot {
      width: 10px;
      height: 10px;
      background-color: #ccc;
      border-radius: 50%;
      cursor: pointer;

      &.selected {
        background-color: #007bff;
      }
    }
  }

  &.carousel-slider {
    overflow: visible;
  }
}

.slider-wrapper {
  &.axis-horizontal {
    padding: 0 20px 20px;
  }
}
