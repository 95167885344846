.fullscreen-modal {
  background: #f5f5f5;
  width: 100%;
  height: auto;
  position: fixed;
  top: 72px;
  bottom: 59px;
  padding-bottom: 40px;
  left: 0;
  overflow: auto;

  @media (max-width: 480px) {
    top: 56px;
    bottom: 0px;
  }

  .page-header {
    h6 {
      padding: 10px !important;
    }

    @media (max-width: 700px) {
      margin: 0px -10px;
    }
  }

  .product-offer {
    color: #000;
    margin-top: 20px;

    &__image {
      padding: 0 12px;
      border-radius: 12px;
      background: #e7e7e7;
      height: 232px;
      text-align: center;

      img {
        height: 100%;
      }
    }

    &__price {
      display: inline-block;
    }

    &__btns {
      button {
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1.5px;
        height: 45px;
        border-radius: 8px;
        line-height: 43px;
      }
    }

    &__name {
      margin-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #d9d9d9;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.135px;
    }

    &__plans {
      display: flex;
      justify-content: space-between;
      margin: 16px 0px 10px 0px;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0.135px;

      &-right {
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        .product-offer__price {
          font-family: Lato;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          sup {
            font-size: 12px;
          }
        }
      }
    }

    &__info {
      color: #444;
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.105px;
    }
  }

  .fullscreen-modal-body {
    max-width: 880px;
    display: block;
    margin: 0 auto;
  }

  .referral-source-modal__title {
    color: var(--gray-4, #222);
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -0.8px;
    width: 100%;
    margin-top: 54px;
    padding: 0;

    @media (max-width: 700px) {
      margin-top: 36px;
      font-size: 24px;
      line-height: 36px;
      font-family: Poppins;
      margin-bottom: 4px;
    }
  }

  .referral-source-modal__subtitle {
    color: var(--gray-4, #222);
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    .product-offer__price {
      sup {
        font-size: 16px;

        @media (max-width: 700px) {
          font-size: 11px;
        }
      }
    }

    @media (max-width: 700px) {
      font-family: Poppins;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      letter-spacing: 0.75;
      margin-bottom: 20px;
    }
  }

  .referral-source-modal__content {
    width: 100%;
    padding: 0px 10px;

    .payment_method__flex {
      @media (max-width: 700px) {
        padding: 0px 5px;
      }
    }
  }

  .question-wrapper {
    @media (max-width: 700px) {
      background: #ffffff;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 24px 20px;
    }
  }

  .question-steps {
    padding: 32px 0;
    background: none;
    color: var(--gray-90, #292a31);
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15px;

    @media (max-width: 700px) {
      padding: 0px;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 400;
      font-family: Poppins;
      color: #000;
    }
  }

  .question_title {
    color: #0d3e6a;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 20px;

    b {
      font-weight: 700;
    }

    @media (max-width: 700px) {
      padding: 0px;
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }
  }

  .referral-source-modal__button {
    margin-top: 20px;

    @media (max-width: 700px) {
      font-size: 12px;
      height: 42px;
      line-height: 42px;
    }
  }

  .btn-group {
    &_column {
      @media (max-width: 700px) {
        display: flex;
        flex-direction: column-reverse;
      }

      .referral-source-modal__button {
        @media (max-width: 700px) {
          width: 100% !important;
        }
      }
    }
  }

  .btn-group .referral-source-modal__button {
    width: 48%;

    &:first-child {
      margin-right: 3%;
    }

    &:last-child {
      width: 49%;
    }
  }

  .health-questions {
    box-shadow: inset 0px 0px 14px rgba(0, 0, 0, 0.05);
    background: #e5eaee;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px;
    position: relative;

    @media (max-width: 700px) {
      display: block;
      background: #fff;
      box-shadow: none;
      padding: 0px;
      border-top: 1px solid #d9d9d9;
    }

    .blue-text-area {
      min-height: 100px;
      color: #414141;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    .describe_reason {
      grid-column: 1/3;

      @media (max-width: 700px) {
        margin-top: 20px;
      }

      p {
        color: var(--gray-3, #444);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 5px;

        @media (max-width: 700px) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }

      textarea {
        border: none;

        @media (max-width: 700px) {
          border: 1px solid #d9d9d9;
        }
      }
    }

    &__item,
    .checkbox-component {
      margin: 0;
      flex: 1;

      @media (max-width: 700px) {
        border-bottom: 1px solid #d9d9d9;
      }

      &.other {
        grid-column: 1/3;
      }

      &.radio-style {
        .hs2-checkbox {
          .hs2-checkbox__checkbox {
            object-position: -100px;
            width: 20px;
            height: 20px;
            border: 1px solid #005285;
            border-radius: 10px;
          }

          &--checked {
            .hs2-checkbox__checkbox {
              background: #005285;
              border: 3px solid #ffffff;
              width: 19px;
              height: 19px;
              margin-right: 1px;
              outline: 1px solid #005285;
            }
          }
        }
      }

      .hs2-checkbox {
        margin: 0px;

        label {
          background: #fff;
          height: 72px;
          border: 1.5px solid #fff;
          transition: all 0.1s linear;
          border-radius: 4px;
          padding: 0px 20px;

          @media (max-width: 700px) {
            height: auto;
            padding: 20px 0px;
            border-radius: 0px;
          }

          p {
            font-size: 14px;
            color: #19191d;
            font-weight: 700;
          }

          span {
            font-size: 14px;
            color: #19191d;
            font-weight: 300;
          }
        }

        &__checkbox {
          opacity: 0.5;
        }

        &__children {
          margin-left: 20px;

          @media (max-width: 700px) {
            margin-left: 16px;
          }
        }

        &--checked {
          label {
            border: 1.5px solid var(--primary-color);

            @media (max-width: 700px) {
              border-color: #fff;
            }
          }

          .hs2-checkbox__checkbox {
            opacity: 1;
          }
        }
      }
    }
  }
}
