.product-selection {
  font-family: var(--inter);

  &__wrap {
    max-width: 489px;
    margin: 40px auto;
    padding: 0px 16px;
  }

  &__form {
    margin-bottom: 80px;

    .hs2-checkbox {
      background: #e8f6ff;
      border-radius: 4px;
      padding: 10px;

      label {
        align-items: flex-start;
        font-family: var(--lato);
        font-size: 18px;
        line-height: 1.4;

        .hs2-checkbox__checkbox {
          margin-top: 5px;
        }

        .hs2-checkbox__children {
          margin-left: 10px;
        }
      }
    }
  }

  h2 {
    margin-bottom: 40px;
    color: #292a31;
    font-weight: 700;
    line-height: 1.3;

    span {
      display: block;
      margin: 8px 0px;
      text-transform: uppercase;
      font-family: var(--oswald);
      font-size: 56px;
      line-height: 1.1;
      letter-spacing: -1.12px;
    }
  }

  &__refs {
    font-weight: 300;

    p {
      margin-bottom: 20px;
      font-size: 20px;
    }

    ul {
      list-style: decimal;
      padding-left: 18px;

      li {
        font-size: 14px;
      }
    }
  }

  .benefits {
    &__title {
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 40px;
    }

    img {
      display: block;
      width: 100%;
    }

    &__item {
      margin-bottom: 60px;

      &_pure {
        border-top: 1px solid #000;
        padding-top: 20px;
        margin: -20px 0px 40px 0px;
      }
    }

    &__info {
      font-family: var(--poppins);
      font-size: 24px;
      font-weight: 700;
      line-height: 1;
      margin: 10px 0px;
    }

    &__text {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.4;
      margin: 0px;

      b {
        font-weight: 700;
      }

      sup {
        font-size: 10px;
      }
    }
  }

  .what-to-expect > div {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: flex-start;
  }

  p,
  a {
    font-size: 14px;
  }

  h5.subtitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

ul.modal-list {
  margin-top: 0;

  li {
    margin-bottom: 0;
  }
}

.fixed-footer {
  position: fixed;
  bottom: 59px;
  background: #fff;
  padding: 32px 20px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;
  text-align: center;
  left: 0;
  right: 0;

  .new-theme & {
    bottom: 0;
  }

  a {
    font-size: 14px;
    text-decoration: underline;
  }

  .default-button {
    max-width: 400px;
  }

  @media (max-width: 600px) {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.new-theme .fixed-footer {
  a {
    text-decoration: none;
  }
}

.choose_product {
  .bp3-control-indicator {
    margin: 0.25rem 1.125rem auto 0.625rem !important;
  }
  .help_decide {
    margin: 32px 0;
    text-align: center;
    text-decoration: underline;
    color: #0066a6;
    cursor: pointer;
  }
  .radio-option {
    .price_row {
      color: #000 !important;
    }
    &._view-module__disabled__Tc8ed {
      opacity: 1 !important;

      .price_row,
      .bp3-control-indicator {
        opacity: 0.5 !important;
      }
      .titles {
        color: #000;
      }
      &:hover {
        background: none;
        box-shadow: none;
      }
    }
  }
}

.product-selection-alert {
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #000000;
  gap: 10px;
  color: #000000;

  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.0075em;
  text-align: left;

  b {
    font-weight: 700;
  }
}
