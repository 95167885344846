@use './mixins.scss';
@import '../../../css/colors';

.default-button {
  border-radius: 8px;
  border: none;
  cursor: pointer;
  letter-spacing: 1.5px;
  height: 48px;
  font-size: 14px;
  font-family: Lato;
  text-transform: uppercase;
  width: 100%;
  &.link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.small {
    padding: 16px 40px;
    font-weight: 400;
    font-size: 12px;
    height: 48px;
    line-height: 13.92px;
    letter-spacing: 8%;
  }
  &.large {
    height: 64px;
    font-size: 16px;
    line-height: 116%;
  }
  &.primary-button {
    color: white;
    box-shadow:
      0 4px 8px rgba(58, 58, 68, 0.12),
      0 8px 16px rgba(90, 91, 106, 0.12);
    background-color: var(--primary-color);
    &:disabled {
      cursor: default;
      opacity: 0.7;
      // background-color: rgba(var(--primary-color), 0.35);
      // color: rgba(255, 255, 255, 0.7);
    }
    &:active:not(:disabled),
    &.active {
      background-color: var(--primary-darken-color);
    }
    &:hover:not(:active):not(&.active):not(:disabled),
    &.hover {
      background-color: var(--primary-lighten-color);
    }
    &.toggled {
      background: var(--primary-darken-color);
    }
  }
  &.outlined-button {
    background-color: $white;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    &:hover:not(:active):not(&.active):not(:disabled),
    &.hover {
      box-shadow:
        0 2px 4px rgba(58, 58, 68, 0.1),
        0 4px 8px rgba(90, 91, 106, 0.1);
    }
    &:disabled {
      cursor: default;
      opacity: 0.65;
      // border: 1px solid rgba(var(--primary-color), 0.35);
      // color: rgba(var(--primary-color), 0.35);
    }
    &:active,
    &.active {
      background-color: var(--primary-lightest-color);
      box-shadow: none;
      color: var(--primary-color);
    }
    &.toggled {
      background: var(--primary-lightest-color);
    }
  }
  &.inline-button {
    background-color: transparent;
    color: var(--primary-color);
    text-decoration: underline;
    &:hover:not(:disabled),
    &.hover {
      text-decoration: none;
    }
    &:disabled {
      cursor: default;
      opacity: 0.65;
      // border: 1px solid rgba(var(--primary-color), 0.35);
      // color: rgba(var(--primary-color), 0.35);
    }
  }

  @media (min-width: 480px) {
    height: 56px;
  }
}

.icon-button {
  background-color: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  width: 52.13px;
  height: 52.13px;
  @media (min-width: 480px) {
    width: 67.84px;
    height: 67.84px;
  }
  &:hover {
    background-color: var(--primary-lighten-color);
  }
  &:active {
    background-color: var(--primary-darken-color);
  }
  &:disabled,
  &[disabled] {
    opacity: 0.65;

    .icon-button__icon {
      opacity: 0.35;
    }
  }
  &.down .icon-button__icon {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
.icon-button__icon {
  width: 19.79px;
}

.default-button.white-button {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: #19191d;
  height: 45px;
  width: auto;
  padding-left: 50px;
  padding-right: 50px;
  &.full {
    width: 100%;
  }
}

.updated-design-button {
  @include mixins.updated-design-button;
}
