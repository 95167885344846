$blue_4: #005285;
$blue_3: #0066a6;
$blue_2: #3385b8;
$blue_1: #f2faff;
$gray_4: #222222;
$gray_6: #4b555a;
$gray_3: #444444;
$gray_2: #e1e1e1;
$gray_1: #f9f9f9;
$gray_5: #f7f7fa;
$gray_7: #9696a0;
$gray_text: #5a5b6a;
$black: #000000;
$white: #ffffff;
$success: #37833b;
$warning: #ff3333;

html {
  --primary-color: #{$blue_3};
  --primary-lighten-color: #{$blue_2};
  --primary-lightest-color: #{$blue_1};
  --primary-darken-color: #{$blue_4};
}
