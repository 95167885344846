/*PROGRESS BAR*/

.filled div[class*='slider-progress']:first-of-type,
.filled div[class*='slider-progress']:last-of-type {
  background: var(--color20);
}
.filled div[class*='slider-progress']:nth-of-type(2) {
  background-color: var(--color70);
}

.filled div[class*='slider-axis'] > div[class*='slider-label'] {
  color: var(--color40);
}

.filled span[class*='slider-handle'] {
  background: var(--color0);
  box-shadow: var(--shadow2dp);
  transition: box-shadow 200ms ease-in-out;
}
.filled:hover span[class*='slider-handle'] {
  background: var(--white);
  background-color: var(--white);
  box-shadow: var(--shadow4dp);
  transition: box-shadow 200ms ease-in-out;
}
.filled span[class*='slider-handle'][class*='active'] {
  background: var(--color50);
  background-color: var(--color50);
  box-shadow: none;
  transition: box-shadow 200ms ease-in-out;
}

.filled span[class*='slider-handle'] > span[class*='slider-label'] {
  background: var(--color5);
  color: var(--color100);
  box-shadow: none;
}
/*.filled span[class*="slider-handle"] > span[class*="slider-handle"]:active {
  background: var(--grey10);
}*/
