/* DIALOG TYPES*/
/*--DEFAULT--*/
.default {
  width: 98%;
  max-width: 30.5rem;
  padding: 2.5rem;
  border-radius: 1.5rem;
}

.header {
  display: flex;
  margin-bottom: 1rem;
}
.header > span[class*='icon'] {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
}
.footer {
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
}
.left-button {
}
.right-button {
  margin-left: 8px;
}

.dense {
  width: 98%;
  max-width: min(90%, 20.5rem);
  padding: 1rem;
  border-radius: 1rem;
}
.dense.header {
  margin-bottom: 0.75rem;
}
.dense.header > span[class*='icon'] {
  margin-right: 0.75rem;
}
.dense.footer {
  margin-top: 1.75rem;
}

.close-button {
  margin-right: 0;
  margin-left: auto;
}
