.protocol {
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.0075em;
  font-family: var(--inter);
  font-weight: 700;
  margin-bottom: 40px;
  &.inline {
    display: inline;
    font-size: 14px;
  }
  &.warrior.uppercase {
    span {
      &:before {
        left: 71px;
      }
    }
  }
  span {
    position: relative;
  }

  &.king {
    span {
      &:before {
        content: '';
        position: absolute;
        background: #f6f8fa url('images/i-title-products-king.svg') center no-repeat;
        background-size: contain;
        width: 19px;
        height: 12px;
        left: -9px;
        top: -4px;
        transform: rotate(-30deg);
      }
    }
  }

  &.lover {
    span {
      &:before {
        content: '';
        position: absolute;
        background: url('images/i-title-products-lover.svg') center no-repeat;
        background-size: contain;
        width: 15px;
        height: 15px;
        top: -7px;
        left: -4px;
        transform: rotate(0deg);
      }
    }

    .title_sm {
      font-size: 32px;
      margin-bottom: 30px;

      @media (max-width: 500px) {
        font-size: 22px;
      }
    }

    .component {
      .mh16 {
        margin: 0px;
      }
    }
  }

  &.warrior {
    span {
      &:before {
        content: '';
        position: absolute;
        background: url('images/i-title-products-warrior.svg') center no-repeat;
        background-size: contain;
        width: 15px;
        height: 24px;
        top: 3px;
        left: 54px;
        transform: rotate(0deg);
      }
    }

    .title_sm {
      font-size: 32px;
      margin-bottom: 30px;

      @media (max-width: 500px) {
        font-size: 22px;
      }
    }

    .component {
      .mh16 {
        margin: 0px;
      }
    }
  }
}
