.fixed {
  padding: 12px;
  width: 376px;
  position: fixed;
  bottom: 0;
  background-color: #f6f8fa;
}

.sustain_shipping_card {
  .separator {
    background-color: #ededf0;
  }
}
