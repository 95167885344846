.discount-banner-exp {
  background-color: #37833b;
  font-family: Poppins;
  color: #ffffff;
  font-size: 14px;
  line-height: 19.5px;
  font-weight: 400;
  letter-spacing: 0.0075em;
  text-align: left;
  padding: 8px 16px;
  margin-bottom: 20px;

  span {
    font-weight: 600;
    sup {
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.0075em;
    }
  }

  sup {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.0075em;
  }
}
