.title_with_icon {
  font-weight: bold;
  font-size: 28px;
  display: flex;
  gap: 4px;
  position: relative;
  font-family: Cambon;
  img,
  .svg-icon {
    margin-right: 16px;
    color: var(--primary-color);
  }
  div.svg-icon {
    display: flex;
    align-items: center;
    > div {
      display: flex;
    }
  }
  .icon-badge {
    position: absolute;
    left: 15px;
    top: -5px;
    border: 2px solid white;
    border-radius: 100%;
    color: white;
    background-color: red;
    height: 23px;
    width: 23px;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    font-family: 'Inter';
  }

  .title-contents {
    width: 100%;
    span[aria-haspopup='true'] {
      float: right;
      padding: 5px;
      cursor: pointer;
    }
  }
}
