@media (min-width: 768px) {
  .plan_selection__header {
    margin-left: -109px;
    margin-right: -109px;
  }
}

.change-subscription-link {
  float: right;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.0075em;
  text-align: left;
}

.multimonth_treatment_plan_options {
  font-family: Poppins;
  letter-spacing: 0.0075em;
  border-radius: 4px;
  text-align: left;

  label {
    margin: 0 !important;
    display: block;
    width: 100%;
  }

  .bp3-control-indicator {
    display: none !important;
  }

  .special-label {
    position: absolute;
    height: 24px;
    padding-left: 10px;
    display: flex;

    img {
      height: 24px;
    }

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #005285;
      padding-left: 5px;
      padding-top: 3px;
    }
  }

  .multimonth_treatment_plan__option {
    display: flex;
  }

  .card-content {
    margin: 10px 20px;
    width: 100%;
    display: flex;
    padding-top: 25px;
    justify-content: space-between;

    @media (max-width: 480px) {
      margin: 10px;
    }

    .titles {
      width: 216px;
      padding-left: 25px;

      @media (max-width: 480px) {
        width: 160px;
        padding-left: 10px;
      }

      .title {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        color: #000;
      }

      .subtitle {
        //styleName: m-body-sm;

        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }

      .savings {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #37833b;
      }

      .hint {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #0066a6;
      }
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;

      .from {
        font-size: 12px;
        font-weight: 400;
      }
      .dollar-sign {
        font-size: 14px;
        font-weight: 500;
        position: relative;
        top: -10px;
      }
      .amount {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        sup {
          font-size: 11px;
        }
      }
      .per-month {
        font-size: 10px;
      }
    }

    .card-image {
      width: 69px;
      height: 69px;
      display: flex;
      align-items: center;

      span {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        text-align: center;
        padding-left: 10px;
        white-space: nowrap;
      }

      img {
        height: 69px;
      }
    }
  }

  &__card-description {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.0075em;
    text-align: left;
    margin-bottom: 31px;

    .card-description__title {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0.0075em;
      text-align: left;
    }
  }
}
