@import '../../../css/colors';

.resource-section {
  &__image {
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(58, 58, 68, 0.12);
    width: 100%;
    display: block;
  }

  &__title {
    color: $black;
    font-family: Cambon, serif;
    font-weight: 600;
    font-size: 20px;
  }

  &__text {
    color: $gray_3;
    font-family: Poppins, serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
  }

  &__link {
    display: inline-block;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 14px;
    font-weight: 600;
    margin-right: 28px;

    &:hover,
    &:active {
      text-decoration: none;
    }

    &::before {
      content: '';
      display: inline-block;
      vertical-align: bottom;
      width: 18px;
      height: 18px;
      background-image: url('images/Icon-Chevron-Right.svg');
      background-size: contain;
      margin-right: 9px;
    }
  }
}
