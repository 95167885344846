@import '../../../css/colors';

.title_with_line {
  border-left: 3px solid var(--primary-lighten-color);
  padding-left: 7px;
  font-weight: bold;
  &.l {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    border-left: 4px solid var(--primary-lighten-color);
  }
  &.xl {
    font-size: 24px;
    border-left: 4px solid var(--primary-lighten-color);
  }
  &.red {
    border-left: 3px solid #d13d38;
  }
  &.orange {
    border-left: 3px solid #ff9f10;
  }
  &.pink {
    border-left: 3px solid #fd8670;
  }
}

.title_without_line {
  font-weight: bold;
  &.subheading {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
  &.list_header {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  &.l {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  &.xl {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
  }
}

.lab_step_paragraph_format {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.lab_upload_paragraph_format {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px !important;
  line-height: 18px;
  color: #444444;
}

.color_gray_3 {
  color: #444444;
}

.subtitle {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.0075em;
  text-align: left;
}

.indented-list-item {
  padding-left: 20px;
  text-indent: -20px;
  margin-left: 20px;
}

.indented-list-item::marker {
  margin-left: -20px;
}
