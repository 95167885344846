/* SLIDER TYPES*/
.def /*default*/ {
  min-width: 0;
}
.def div[class*='slider-track'] {
  height: 0.25rem;
  top: 0.625rem;
  border-radius: 99px;
}

.def span[class*='slider-handle'] {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 99px;
}

.def span[class*='slider-handle'] > span[class*='slider-label'] {
  margin-left: 0.75rem;
  border-radius: 0.25rem;
  top: 0.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  min-width: 40px;
  height: 20px;
  padding: 4px 6px;
  text-align: center;
  line-height: 100%;
}
.def div[class*='slider-axis'] > div[class*='slider-label'] {
  border-radius: 0.25rem;
  top: 0.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body);
  letter-spacing: var(--letter_spacing_body_small);
  min-width: 40px;
  height: 20px;
  padding: 4px 6px;
  text-align: center;
  line-height: 100%;
}
.def[class*='vertical'] div[class*='slider-axis'] > div[class*='slider-label'] {
  top: initial;
  left: 0.25rem;
}
.def[class*='vertical'] span[class*='slider-handle'] > span[class*='slider-label'] {
  top: initial;
  left: 0.5rem;
}
.def[class*='vertical'] span[class*='slider-handle'] {
  left: -0.25rem;
}
