.discount-coupons-container {
  .subtitle {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.0075em;
    text-align: left;
    padding-top: 20px;
  }
  .coupon-code-input {
    &__button {
      &__apply {
        right: 14px;
      }
    }
  }
}
.discount-coupons-list {
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.0075em;
  text-align: left;
  color: rgba(55, 131, 59, 1);
  margin-bottom: 24px;
  .price_row > div:first-child {
    font-size: 12px !important;
    line-height: 18px;
    font-weight: 600;
  }
  .price_row > div:last-child {
    margin-top: 10px;
  }
  .coupon_code {
    word-wrap: break-word;
    max-width: 70%;
  }
}
