@use './mixins.scss';
@import '../../../css/fonts';

@mixin blue-text-area--small {
  min-height: 148px;
}

@mixin blue-text-area--large {
  min-height: 256px;
}

.text-area {
  width: 100%;
  border-radius: 8px;
  border: none;
  padding: 16px;
  resize: vertical;
}
.blue-text-area {
  width: 100%;

  &.small {
    @include blue-text-area--small;
  }
  &.large {
    @include blue-text-area--large;
  }

  @include mixins.text-input;
  &.updated-design {
    @include mixins.updated-design-input;
  }
}
@media (max-width: 479px) {
  .blue-text-area {
    @include blue-text-area--small;
  }
}
@media (min-width: 480px) {
  .blue-text-area {
    @include blue-text-area--large;
  }
}
