.google_autocomplete_container {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 0px;
  cursor: pointer;
  margin-top: 5px;

  li {
    border-radius: 8px;
    padding: 5px 16px;
  }

  li:hover {
    background-color: #f0f0f0;
  }

  .google-logo-section {
    display: flex;
    padding: 0px 16px;
    justify-content: right;
    img {
      width: 70px;
      margin-left: 5px;
    }
  }
}
