.grid-container {
  display: grid;
  height: 100%;
  margin: 0 auto;
  grid-template-columns: 1fr;
  grid-template-rows: auto 0 1fr;
  grid-template-areas:
    'header'
    'nav'
    'main'
    'footer';
}
.admin-grid-container {
  background-color: #f6f8fa;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto 0 1fr;
  grid-template-areas:
    'header'
    'nav'
    'main'
    'footer';
}

main {
  overflow-y: scroll;
  grid-area: main;
  display: flex;
  flex-direction: column;
}
.new-theme {
  main {
    overflow-y: initial;
  }
}
nav {
  grid-area: nav;
}
footer {
  grid-area: footer;
}
.centered {
  flex: 1;
  width: 100%;

  @media (min-width: 536px) {
    margin: 0 auto;
    max-width: 512px;
  }

  &.updated-design {
    @media (min-width: 700px) {
      max-width: 586px;
    }
  }

  &.-middle {
    @media (min-width: 512px) {
      max-width: 684px;
      width: 100%;
    }
  }
}

.margin-centered {
  margin: 0 auto;
}
