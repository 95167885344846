.product-image {
  padding: 0 12px;
  border-radius: 12px;
  background: #e7e7e7;
  height: 232px;
  text-align: center;

  &.with-title {
    height: 142px;
  }

  img {
    height: 100%;
    display: inline;
  }
}
