@import '../../../css/colors';

.referral-source-modal.bb-relaunch {
  .referral-source-modal {
    &__title,
    &__content,
    &__content p {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
      font-family: var(--inter);
    }

    &__content p {
      padding-bottom: 25px;

      b {
        font-weight: 700;
      }

      i {
        font-style: italic;
      }

      a {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }

  .bb-info {
    display: flex;
    gap: 40px;

    @media (max-width: 480px) {
      flex-direction: column;
    }

    &__image {
      width: 120px;
      height: 200px;
      background: url('images/bg-bb-notification.png') center no-repeat;
      background-size: contain;
      flex-shrink: 0;
    }
  }

  .bb-options {
    li {
      padding-left: 25px;
      padding-bottom: 25px;
      background: url('images/Icon-Bullet-Checkmark.svg') top left no-repeat;
      background-size: 22px;
    }
  }

  .author {
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    gap: 25px;

    &__image {
      background: url('images/bg-cam-adk-notification.png') center no-repeat;
      background-size: contain;
      width: 80px;
      height: 90px;
    }

    &__text {
      font-size: 14px;

      b {
        font-weight: 700;
      }
    }
  }

  .bp3-icon.bp3-icon-cross svg {
    fill: #c7c7c7 !important;
  }

  .primary-button {
    max-width: 370px;
    margin: 0px auto;
    display: block;
  }
}
