@import '../../../../css/colors';

.herman-title {
  grid-area: title;
  position: relative;
  background-color: var(--primary-color);
  z-index: -10;
  padding: 40px 0;
  @media (max-width: 479px) {
    padding: 20px 0;
  }
  .lion-image {
    position: absolute;
    right: 0;
    top: -100px;
    width: 350px;
    opacity: 0.05;
  }
}

.description-alert {
  color: #a40000;
}
