.centered.flex-column.payment {
  @media (max-width: 479px) {
    width: 100%;
  }
}

.centered {
  &.updated-design {
    .content {
      @media (max-width: 480px) {
        padding: 0 16px;
      }
    }

    .page-header {
      @media (min-width: 700px) {
        margin-left: -123px;
        margin-right: -123px;
      }
    }
    &.payments {
      @media (min-width: 700px) {
        max-width: 448px !important;
      }

      label {
        &.bp3-control {
          margin: 0;
        }
        .bp3-control-indicator {
          margin: 3px 14px 0 0 !important;
        }
      }
    }
  }
}

.bp3-dialog {
  @media (max-width: 480px) {
    margin: 30px 12px !important;
  }
}

.price-from {
  text-align: center;
  font-size: 12px;
  font-weight: 300;
}

.annual_lab_checkout {
  .payment_card .flex {
    gap: 0;
  }
  .billing_error {
    color: #c8372d;
  }
  .flex1:first-child {
    margin-right: 20px;
  }
}
.payment_card {
  background-color: #fff;
  box-shadow:
    0px 2px 4px rgba(58, 58, 68, 0.1),
    0px 4px 8px rgba(90, 91, 106, 0.1);
  border-radius: 4px;
  padding: 30px;
  .flex {
    gap: 33px;
  }
  .gap10 {
    gap: 10px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.payment_method {
  &__protocol {
    font-size: 36px;
    font-family: var(--lato);
    font-weight: 700;
    margin-bottom: 40px;

    @media (max-width: 479px) {
      font-size: 26px;
      padding: 0px 15px;
      margin-bottom: -10px 0px 30px 0px;
    }

    span {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: #f6f8fa url('images/i-title-products-king.svg') center no-repeat;
        background-size: contain;
        width: 25px;
        height: 18px;
        left: -11px;
        top: -4px;
        transform: rotate(-30deg);

        @media (max-width: 479px) {
          width: 17px;
          height: 12px;
          left: -7px;
          top: -4px;
        }
      }
    }
    &_lover {
      span {
        &:before {
          content: '';
          position: absolute;
          background: url('images/i-title-products-lover.svg') center no-repeat;
          background-size: contain;
          width: 22px;
          height: 21px;
          top: -9px;
          left: -5.5px;
          transform: rotate(0deg);

          @media (max-width: 479px) {
            width: 15px;
            height: 15px;
            left: -4px;
            top: -6px;
          }
        }
      }
    }
    &_yolo {
      max-width: 456px;
      margin-left: auto;
      margin-right: auto;
    }
    &_follow-up {
      max-width: 456px;
      padding: 0px 10px;
      font-size: 30px;
      margin: 30px auto;
      text-transform: uppercase;

      &.payment_method__protocol {
        span {
          &:before {
            width: 25px;
            height: 18px;
            left: -12px;
            top: -7px;
          }
        }

        &_lover {
          span {
            &:before {
              width: 22px;
              height: 21px;
              top: -11px;
              left: -6.5px;
            }
          }
        }
      }
    }
  }
  &__card {
    padding: 30px;
    background-color: #fff;
    box-shadow:
      0px 2px 4px rgba(58, 58, 68, 0.1),
      0px 4px 8px rgba(90, 91, 106, 0.1);
    border-radius: 4px;
  }
  &__label {
    color: #414141;
    font-size: 14px;
    margin-bottom: 4px;
  }
  &__accept {
    display: block;
    margin-top: 33px;
    font-size: 12px;
    align-items: center;
    @media (max-width: 479px) {
      font-size: 10px;
    }
  }
  &__old_price {
    color: #0066a6;
    text-decoration: line-through;
    font-size: 13px;
    margin-right: 22px;
    margin-top: 10px;
  }
  &__disabled_alert {
    color: #fff;
    width: 100%;
    padding: 10px;
    background: #888;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 12px !important;
  }
  &__bb_description {
    font-size: 12px !important;
    margin-top: 10px;
    color: #19191d;
  }
  &__flex {
    margin: 0 auto;
    .blue_background {
      background-color: #e8f6ff;
      padding: 10px;
    }
    &.yolo {
      max-width: 456px;
      margin: 0 auto;
      display: block;
    }
    & {
      display: flex;
      gap: 50px;
    }
    &:not(.yolo) > div:first-child {
      width: 456px;
      margin: 0 auto;
      @media (max-width: 479px) {
        width: 100%;
      }
    }
    .pointer {
      margin-right: 7px;
    }
    .info-icon {
      position: relative;
      top: 2px;
      left: 8px;
    }
    & {
      font-family: Lato;
    }
    h5 {
      font-size: 30px;
      position: relative;
      margin-bottom: 32px;
      font-weight: 700;
      margin-top: 40px;
      text-transform: uppercase;
      img {
        position: absolute;
        left: -18px;
        top: -10px;
      }
    }
    @media (max-width: 479px) {
      flex-direction: column;
      padding: 0 15px;
    }
    .title_with_line {
      font-family: var(--inter);
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0.0075em;
      margin-bottom: 20px;
    }
    .primary-button {
      border-radius: 8px;
    }
    .opacity {
      opacity: 0.5;
    }
  }
  &__billing_address {
    background-color: #fff;
    input {
      background-color: #fff;
      border-radius: 4px;
    }
    .flex {
      gap: 25px;
      align-items: center;
    }
    &.new {
      padding: 0px;
      box-shadow: none;
      margin-bottom: 0;
    }
    & {
      padding: 30px;
      box-shadow:
        0px 2px 4px rgba(58, 58, 68, 0.1),
        0px 4px 8px rgba(90, 91, 106, 0.1);
      border-radius: 4px;
      margin-bottom: 50px;
      margin-top: 20px;
    }
  }
}

.when-charged-title {
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 25px;
  color: #272833;
  font-weight: 700;
}

.money-back-modal-title {
  font-size: 24px;
  color: #272833;
  margin-top: 40px;
  margin-bottom: 25px;
  line-height: 33px;
  font-weight: bold;
}

.payment_method_info {
  button {
    border-radius: 4px !important;
  }
  .default-button {
    height: 45px;
  }
  hr {
    background-color: #d9d9d9;
    height: 1px;
    border: none;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .card_info {
    display: flex;
    gap: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .payment_button {
    border: 1px solid #d9d9d9;
    text-align: center;
    padding: 14px 0;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
  }
  .payment_method__card {
    box-shadow: none;
    padding: 0;
  }
}

.price_row {
  display: flex;
  font-family: Poppins;
  font-weight: 500;
  font-size: 18px;
  & > div:last-child {
    margin-left: auto;
  }
  & > div:first-child {
    font-size: 18px;
  }
  sup {
    font-size: 10px;
    position: relative;
    top: -7px;
  }
  .desc {
    font-weight: normal;
    font-size: 14px;
  }
  .list_style {
    display: list-item;
    list-style-position: inside;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    input {
      cursor: not-allowed;
    }

    p {
      cursor: not-allowed !important;
    }
  }
}

.new-theme {
  .price_row {
    font-family: Inter;
    sup {
      @apply relative top-[-4px] lg:top-[-6px] text-[0.625rem] lg:text-[0.75rem];
      font-feature-settings: 'sups' on;
    }
    sub {
      @apply relative text-[0.875rem] lg:text-[1rem];
      font-feature-settings: 'ss05' on;
    }
  }
}

.due_today_lab_kit {
  color: #757575;
  margin-top: 6px;
  font-size: 10px;
  font-weight: 300;
}

.due_today_description {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.0075em;
  text-align: left;
  color: #444444;
}

.plan_summary {
  margin-top: 20px;
  background-color: #e8f6ff;
  padding: 20px;
}

.plan_description {
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 16px;
  line-height: 150%;
}

.action_button {
  min-width: max-content;
  width: auto;
  text-transform: none;
  padding: 10px 12px;
  height: auto;
  border-radius: 8px !important;
  background: #005285;
  border: 1px solid #f0f0f0;

  &.cancel-no-background {
    border: none;
    text-decoration: underline;
    background: none;

    &:hover {
      box-shadow: none !important;
    }

    &:focus {
      background-color: #ffffff;
    }
  }

  &.cancel {
    border: none;
    text-decoration: underline;

    &:hover {
      box-shadow: none !important;
    }

    &:focus {
      background-color: #ffffff;
    }
  }

  &:disabled {
    color: #444444;
    background-color: #f0f0f0;
  }
}

.action_buttons > * {
  width: 100%;
}

.photo_upload {
  display: flex;
  align-items: center;
}

.byol-form-warning {
  color: #c8372d;
  font-size: 14px;
  line-height: 21px;
}

.byol-form-success {
  color: #37833b;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;

  &:before {
    content: '';
    background: url('images/check_green_circle.svg') center no-repeat;
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    position: relative;
    top: -2px;
  }
}

.full_width {
  width: 100%;
}

.action_icons {
  padding-left: 10px;
}

.action_icon {
  vertical-align: middle !important;
  padding-top: 0 !important;
  color: #005285;
  cursor: pointer;
}

.own_lab_dialog {
  width: 480px !important;
  padding: 32px !important;

  @media (max-width: 479px) {
    width: 355px !important;
    padding: 16px !important;
  }
}

.own_lab_dialog_content > * {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px !important;
  line-height: 140% !important;
  letter-spacing: normal !important;
}

.radio_button {
  accent-color: #0066a6;
  background-color: #0066a6;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.display_flex {
  display: flex;
}

.minW30 {
  min-width: 30px;
}

.progress_bar_container {
  position: relative;
}

.progress_bar_completed {
  position: absolute;
  top: -1.75rem;
  right: 0;
  & > * {
    color: #0066a6 !important;
  }
}

.poppins_14 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
}

.color_44 {
  color: #444444;
}

.color_22 {
  color: #222222;
}

.color_e1 {
  color: #e1e1e1;
}

.radio_button_small {
  accent-color: #0066a6;
  background-color: #0066a6;
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-right: 10px;
}

.question_container_title {
  position: absolute;
  top: -10px;
  background: white;
  color: #19191d;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  padding: 0 10px;
}

.error_message {
  color: #c8372d;
  font-size: 10px;
  display: block;
}

.file_error_message {
  margin: 8px 0;
  color: #c8372d;
  font-size: 10px;
  display: block;
}

.text18 {
  font-size: 18px !important;
}

.divider {
  background: #222 !important;
  height: 1px !important;
  align-self: stretch !important;
}

.DayPickerInput {
  width: 100%;
}

.modal_input_row {
  width: 100%;
  position: relative;

  & > :nth-child(2) {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    input[type='number']:hover,
    input[type='number']:focus {
      -moz-appearance: number-input;
    }

    input {
      height: 40px;
      width: 100%;
      padding: 10px 14px;
      margin-right: 10px;

      &.initial {
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        background: #fff;
      }

      &.valid {
        border-radius: 4px;
        border: 1px solid #3385b8;
        background: #f2faff;
      }

      &.error {
        border-radius: 4px;
        border: 1px solid #c8372d;
        background: #fff;
      }
    }
  }

  & > :nth-child(3) {
    position: absolute;
    right: 0px;
    top: 28px;

    & > span {
      margin-right: 10px;
    }

    & > .warning {
      color: #c8372d;
    }

    & > .valid {
      color: #3385b8;
      padding-top: 0;
    }
  }
}

.custom_modal_input_row {
  width: 100%;
  position: relative;

  & > :nth-child(2) {
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
    input[type='number']:hover,
    input[type='number']:focus {
      -moz-appearance: number-input;
    }

    input {
      height: 40px;
      width: 100%;
      padding: 10px 14px;
      margin-right: 10px;

      &.initial {
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        background: #fff;
      }

      &.valid {
        border-radius: 4px;
        border: 1px solid #3385b8;
        background: #f2faff;
      }

      &.error {
        border-radius: 4px;
        border: 1px solid #c8372d;
        background: #fff;
      }
    }
  }

  & > :nth-child(3) {
    position: absolute;
    right: 0px;
    top: 49px;

    & > span {
      margin-right: 10px;
    }

    & > .warning {
      color: #c8372d;
    }

    & > .valid {
      color: #3385b8;
      padding-top: 0;
    }
  }
}
