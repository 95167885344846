.alert {
  padding: 20px;
  box-shadow:
    0px 2px 4px rgba(58, 58, 68, 0.1),
    0px 4px 8px rgba(90, 91, 106, 0.1);
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 14px;
  img {
    cursor: pointer;
  }
  .flex div:last-child:not(:first-child) {
    text-align: right;
    flex-grow: 1;
  }
  &.success {
    background-color: #32ba7c;
    color: #fff;
  }
  &.error {
    background-color: #c9372d;
    color: #fff;
  }
  &.no-uppercase {
    text-transform: none;
  }
  a.white {
    color: white !important;
  }
}
