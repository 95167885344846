.contact-us {
  font-family: var(--lato);

  .wrapper {
    max-width: 1170px;
    margin: 0px auto;

    @media (max-width: 1200px) {
      max-width: 95%;
    }
  }

  .top {
    background: var(--primary-color);
    height: 280px;
    text-align: center;
    color: #fff;

    @media (max-width: 1000px) {
      height: 258px;
    }

    h1 {
      padding: 48px 0px 24px 0px;
      font-size: 48px;
      font-weight: 500;

      @media (max-width: 1000px) {
        padding: 40px 0px 20px 0px;
      }
    }
  }

  .options {
    display: flex;
    gap: 28px;
    margin: -90px 0px 48px 0px;

    @media (max-width: 1000px) {
      flex-direction: column;
      gap: 32px;
    }

    @media (max-width: 500px) {
      gap: 40px;
    }

    &__item {
      background: #fff;
      color: #212121;
      flex: 1;
      border-radius: 10px;
      padding: 36px 20px;
      box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media (max-width: 1000px) {
        padding: 40px;
        min-height: 320px;
      }

      @media (max-width: 500px) {
        padding: 40px 20px;
        min-height: auto;
      }

      h2 {
        font-size: 36px;
        font-weight: 500;

        @media (max-width: 500px) {
          font-size: 26px;
        }
      }

      p {
        font-family: var(--poppins);
        line-height: 24px;
        margin-top: 20px;

        @media (max-width: 500px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }

    &__info {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-top: 20px;

      a {
        background: var(--primary-color);
        font-family: var(--poppins);
        padding: 8px 20px;
        color: #fff;
        box-shadow:
          0px 4px 8px rgba(58, 58, 68, 0.12),
          0px 8px 16px rgba(90, 91, 106, 0.12);
        border-radius: 4px;

        &:hover {
          background: var(--primary-lighten-color);
        }
      }

      p {
        font-size: 12px;
        line-height: 16px;
        color: #212121;
        margin: 0px;

        b {
          font-weight: 600;
        }
      }
    }
  }

  .discord {
    max-width: 70%;

    @media (max-width: 1000px) {
      max-width: 100%;
    }

    h2 {
      color: --grey90;
      font-size: 24px;
      font-weight: 700;
      padding-left: 10px;
      border-left: 4px solid var(--primary-color);
    }

    p {
      font-family: var(--poppins);
      line-height: 24px;
      margin-top: 20px;
      margin-bottom: 20px;

      @media (max-width: 500px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
