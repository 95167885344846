.generic-refund-policy {
  font-family: 'Poppins' !important;
  font-size: 14px !important;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0.0075em;
  text-align: left;

  ul {
    list-style-type: disc;
  }
}
