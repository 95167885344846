.lover_treatment_plan {
  font-family: Lato;

  &__header {
    font-size: 20px;
    font-weight: 700;
    font-family: Lato;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  &__flex {
    display: flex;
    gap: 10px;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 6px;
  }
  .paragraph {
    font-size: 12px !important;
    width: 340px;
  }
  .titles {
    margin-bottom: 4px;
  }
  .price {
    margin-left: auto;
  }
  .price .value {
    display: flex;
  }
  .label {
    background-color: #d0e5f2;
    padding: 2px 4px;
    margin-top: 2px;
    font-size: 10px;
  }
  &__option label {
    width: 100%;
  }
}
.mtn15 {
  margin-top: -15px;
}
