.pharmacy-step {
  @media (max-width: 535px) {
    margin: 0 12px;
  }

  .page-header {
    @media (max-width: 535px) {
      margin: 0 -12px;
    }

    h6 {
      @media (max-width: 536px) {
        border-radius: 0;
      }

      @media (min-width: 700px) {
        margin: 0 -57px;
      }
    }
  }

  .collaborates {
    font-size: 11px;
  }
  .pharmacy-label {
    padding-top: 8px;
    text-align: left;

    @media (max-width: 700px) {
      padding-top: 0;
      text-align: center;
    }
  }

  .pharmacy-badges {
    position: absolute;
    right: 0;
    top: 25%;
    width: 220px;
    display: flex;
    align-items: center;
    pointer-events: none;

    div {
      display: inline;
      padding-right: 10px;
      overflow-y: auto;
      flex: 1 1 auto;
      border-radius: 2px;
      img {
        width: 100%;
      }
    }

    @media (max-width: 699px) {
      margin-top: 16px;
      position: relative;
      width: 100%;
      justify-content: space-between;
      align-items: initial;

      div {
        padding: 10px;
        background-color: white;
        box-shadow: var(--shadow2dp);
        width: 100%;
        margin-left: 15px;
        display: flex;
        &:first-child {
          margin-left: 0;
        }
        img {
          object-fit: contain;
          max-width: 100%;
          width: auto;
          margin: auto;
        }
      }
    }
  }

  .other-option-checkbox {
    display: flex;
    align-items: flex-start;
  }

  .different-pharm {
    font-size: 14px;
  }

  .input_mask__span {
    line-height: 8px;
  }

  .pharmacy-input {
    border: none;
    box-shadow: var(--shadow2dp);
    margin: 0px 2px;
    width: 99%;
  }

  &.updated-design {
    text-align: justify;
    --font_size_body: 18px;

    @media (max-width: 480px) {
      margin: 0 16px;
    }

    .pharmacy-badges {
      width: auto;
      gap: 16px;
      div {
        margin: 0;
        padding: 0;
        img {
          max-height: 25px;
        }
      }
      & {
        padding-right: 16px;
      }
      @media (max-width: 480px) {
        gap: 14px;
        padding-right: 0;
        height: 50px;
        div {
          padding: 10px;
          img {
            max-height: auto;
          }
        }
      }
    }
    .description {
      font-size: 16px;
      --font_size_body: 16px;
      --font_weight_body: 300;
    }
    .bp3-card.danger-card {
      border-radius: 8px;
      font-family: var(--poppins);
      font-size: 16px;
      --font_size_body_small: 16px;
      --font_weight_body: 300;
    }
    .different-pharm {
      font-size: 16px;
      --font_weight_body: 300;
    }
    .other-option-checkbox {
      --font_size_body_small: 16px;
      --font_weight_body: 300;
    }
    .pharmacy-form {
      > .flex {
        gap: 16px;
      }
      .hs2-text-input__input {
        border-radius: 4px;
      }
      .dropdown-wrapper .select__control {
        border-radius: 4px;
        border: none;
      }
      .pharmacy-input {
        border-radius: 4px;
        box-shadow:
          0px 2px 4px rgba(58, 58, 68, 0.1),
          0px 4px 8px rgba(90, 91, 106, 0.1);
      }
    }
  }
}
