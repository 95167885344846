@import '../../../css/colors';
@import '../../../css/shadow';

.header__logo {
  cursor: pointer;
  gap: 15px;
  display: flex;
  &__crown {
    position: relative;
    top: -2px;
  }
}

.header__logo--no-click {
  cursor: auto;
}

.header__menu--noclick {
  cursor: auto;
  float: left;
}

.header__menu {
  cursor: pointer;
  float: left;
}

.left-side a {
  display: block;
}

.header {
  grid-area: header;
  background-color: #000;
  padding: 16px;
  box-shadow:
    0px 8px 16px rgba(58, 58, 68, 0.12),
    0px 16px 32px rgba(90, 91, 106, 0.12);
  font-family: Lato;
  @media (max-width: 480px) {
    padding: 0;
  }

  .header__content {
    gap: 16px;
    max-width: 1520px;
    margin: 0 auto !important;
    display: flex;
    align-items: center;
    @media (max-width: 480px) {
      display: none;
    }
  }
  .clickable {
    display: none;
    &__menu {
      img {
        display: block;
      }
    }
    &__logo {
      position: absolute;
      left: 50%;
      margin-left: -16px;

      img {
        display: block;
      }
    }
    @media (max-width: 480px) {
      display: flex;
      align-items: center;
    }
  }
  .header__menu-items {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    font-size: 16px;
    gap: 25px;
    &:last-child {
      margin-left: auto;
    }
    a {
      color: #fff;
    }
  }
  .header__logout_button {
    box-sizing: border-box;
    border-radius: 4px;
    text-decoration: none !important;
    padding: 8px 20px;
    white-space: nowrap;
    background: var(--primary-color);

    &:active:not(:disabled),
    &.active {
      background-color: var(--primary-darken-color);
    }
    &:hover:not(:active):not(&.active):not(:disabled),
    &.hover {
      background: var(--primary-lighten-color);
    }
  }
  &.black {
    padding: 6px 16px;
  }
}

@media (min-width: 480px) {
  .herman-mobile {
    display: none;
  }
  .herman-desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: $shadow-dark-16;
    height: 72px;
    padding: 0 80px;
    .options {
      display: flex;
      font-family: Lato;
      & > div {
        cursor: pointer;
        margin-left: 32px;
      }
    }
  }
}
