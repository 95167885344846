.bp3-input-group.bp3-disabled {
  opacity: 0.35;
}
.input {
  &._view-module__error-helper-text___1qWPr {
    input.bp3-input {
      border-color: #c8372d !important;
    }
  }
  &._view-module__success___1Xb9u {
    input.bp3-input {
      border-color: #37833b !important;
    }
  }
  input.bp3-input {
    background-color: #f9f9f9 !important;
    border: 1px solid #e1e1e1 !important;
    border-radius: 8px !important;
    &:focus {
      border-color: var(--primary-color) !important;
    }
    &:disabled {
      background-color: #f9f9f9 !important;
      border-color: #e1e1e1 !important;
    }
  }
  &.large {
    height: 64px;
  }
  &.medium {
    height: 56px;
  }
  &.small {
    height: 48px;
  }
}
