.generic_payment_page {
  &__card {
    padding: 23px;
    .lover_treatment_plan__option label {
      width: auto !important;
    }
    span.small {
      font-size: 14px;
    }
  }
  #card_number {
    width: 100%;
  }
  .title_with_line {
    font-family: var(--inter);
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.0075em;
    margin-bottom: 20px;
  }
  &__bb_info {
    margin-left: 29px;
    p {
      font-family: Poppins;
    }
    &__enjoy {
      color: #005285;
      margin-bottom: 6px;
      font-weight: 500;
    }
  }
  &__per_month_label {
    font-size: 12px;
    font-family: Lato;
    font-weight: 400;
  }
  &__price_format_free_container {
    display: inline-flex;
  }
  &__price_label_free {
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 27px;
    margin-left: 5px;
  }
  &__price_strikethrough {
    position: relative;
  }
  &__price_strikethrough:before {
    max-width: 46px;
    width: 46px;
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;

    -webkit-transform: rotate(-21deg);
    -moz-transform: rotate(-21deg);
    -ms-transform: rotate(-21deg);
    -o-transform: rotate(-21deg);
    transform: rotate(-21deg);
  }
  .payment_button {
    border: 1px solid #0066a6;
    color: rgba(0, 102, 166, 1);
  }
  &__modal {
    .payment_card {
      box-shadow: none;
      padding: 0;
    }
  }
  &__change_subs {
    margin-left: auto;
    color: rgba(0, 102, 166, 1);
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
  }
  &__multi_month_variant {
    font-size: 18px;
    font-weight: 400;
  }
  .aknowledgement {
    margin-bottom: 0;

    label {
      align-items: flex-start;
    }
  }
  &__protocol_header {
    font-family: Poppins;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 24px;
  }
  &__bb_label {
    font-size: 18px;
    font-family: Poppins;
    font-weight: 500;
  }
  &__plan_summary {
    p {
      font-family: Poppins;
      font-size: 12px !important;
    }
  }
  &__shipping_address_title {
    font-family: Poppins;
    font-size: 18px;
  }
  &__shipping_address_update {
    font-family: Poppins;
    font-size: 12px;
  }
  .opacity {
    opacity: 0.5;
  }
}
.multimonth-dropdown {
  position: absolute !important;
  top: -8px;
  right: 0;
  width: 170px;
}
.lover_treatment_plan__option .radio-option {
  min-height: 0 !important;
}
