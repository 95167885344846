.main-area {
  padding: 50px 0 35px 0;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 700px) {
    padding: 40px 0;
  }

  .text-wrapper {
    text-align: left;

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-right: 55%;
    }

    @media (min-width: 100px) and (max-width: 767px) {
      margin-right: 64%;
    }
  }

  .text-container {
    @media (max-width: 700px) {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .upload-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 130%;
    letter-spacing: 0.0075em;
    word-wrap: break-word;
    margin-top: 10px;
    text-align: left;

    @media (max-width: 700px) {
      font-size: 30px;
      width: 100%;
    }
  }

  .weight-font {
    font-weight: bold;
  }

  .front-of-head {
    background: url('/app/javascript/images/front-of-head.png') center no-repeat;
    background-size: contain;
    height: 300px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .indicator-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */
    color: #0066a6;
    word-wrap: break-word;
  }

  .diagnosis-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 140%;
    color: #000000;
    margin-top: 30px;
    text-align: left;

    @media (max-width: 700px) {
      width: 100%;
    }
  }

  .button-container {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .take-photo-button {
    border-radius: 10px;
    width: 50%;
    font-weight: 600;
    color: #ffffff;

    @media (max-width: 700px) {
      width: 35%;
    }
  }

  .select-photo-button {
    width: 50%;
    margin-right: 10px;
    color: #292a31;
    box-shadow:
      0 4px 8px rgba(58, 58, 68, 0.12),
      0 8px 16px rgba(90, 91, 106, 0.12);
    border-radius: 8px;
    border: none;

    @media (max-width: 700px) {
      width: 35%;
    }
  }

  .lock-img {
    background: url('/app/javascript/images/lock.png') center no-repeat;
    width: 19px;
    height: 25px;
    margin-right: 9px;
    background-size: contain;
  }

  .support-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    color: #0066a6;
  }

  .tls-security-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #292a31;
    margin-top: 27px;
    display: inline-flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: row;
    align-items: center;
  }

  .ensure-that-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25%;
    color: #000000;
    margin-bottom: 30px;
    text-align: left;

    @media (max-width: 700px) {
      width: 100%;
    }
  }

  .continue-button {
    width: 40%;

    @media (max-width: 700px) {
      width: 80%;
    }
  }

  .w-65 {
    width: 65%;
  }

  .error-img {
    background: url('/app/javascript/images/Thinking.svg') center no-repeat;
    width: 70px;
    height: 70px;
    margin-right: 9px;
    background-size: contain;

    @media (min-width: 768px) and (max-width: 1024px) {
      margin-right: 55%;
    }

    @media (min-width: 100px) and (max-width: 767px) {
      margin-right: 30%;
    }
  }

  .back-of-head {
    background: url('/app/javascript/images/back-of-head.svg') center no-repeat;
    background-size: contain;
    height: 300px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .top-of-head {
    background: url('/app/javascript/images/top-of-head.svg') center no-repeat;
    background-size: contain;
    height: 300px;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .confirm-button {
    border-radius: 10px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 30px;

    @media (max-width: 700px) {
      width: 35%;
    }
  }

  .photo-wrapper {
    position: relative;
    display: inline-block;
  }

  .photo-wrapper img {
    max-width: 456px;
    max-height: 300px;
    @media (max-width: 700px) {
      max-width: 300px;
    }
  }

  .photo-wrapper button {
    left: 75%;
    top: 80%;
    position: absolute;
    opacity: 0.8;
    width: 110px;
    height: 35px;
    border-radius: 6px;
    @media (max-width: 700px) {
      left: 65%;
    }
  }

  .review-text-container {
    margin-top: 30px;
    @media (max-width: 700px) {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .title-display {
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.0125em;
    word-wrap: break-word;
    text-align: left;
  }

  .track-growth-text {
    text-align: left;
  }

  .picture-instruction img {
    display: block;
    margin-top: -120px;
    margin-left: 55%;
    @media (max-width: 700px) {
      width: 155px;
    }
  }

  .tip {
    font-weight: bold;
    color: #000000;
  }

  .tip-text {
    text-align: left;
    background-color: #f9fdff;
    border-bottom: 1px solid #cecece;
    border-radius: 8px;
    box-shadow:
      0 4px 8px rgba(58, 58, 68, 0.12),
      0 8px 16px rgba(90, 91, 106, 0.12);
    padding: 15px;
  }

  .product-info_name {
    content: '';
    position: fixed;
    background: url('images/i-title-products-warrior.svg') center no-repeat;
    background-size: cover;
    width: 9px;
    height: 30px;
    top: 120px;
    left: 600px;

    @media (min-width: 768px) and (max-width: 1024px) {
      top: 110px;
      left: 119px;
    }

    @media (min-width: 100px) and (max-width: 767px) {
      top: 110px;
      left: 119px;
    }
  }
}
