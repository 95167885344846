@use './mixins.scss';
@import '../../../css/colors';

.hs2-text-input__label {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: $gray_3;
  height: 14px;
  margin-bottom: 8px;
  &.disabled {
    opacity: 0.35;
  }
  &.small {
    font-size: 12px;
    margin-bottom: 2px;
  }
}
.hs2-text-input.small {
  .hs2-text-input__input {
    height: 48px;
  }
}
.hs2-text-input__input {
  @include mixins.text-input;
  & {
    height: 56px;
  }
  &.updated-design {
    @include mixins.updated-design-input;
  }

  &.large {
    height: 64px;
  }
  &.small {
    height: 48px;
  }
}

.hs2-text-input__container {
  position: relative;
  font-family: Univers;
}

.hs2-text-input__state {
  position: absolute;
  top: 0;
  height: 100%;
  width: 20px;
  right: 20px;
}

.hs2-text-input__error {
  color: $warning;
  margin-top: 8px;
  font-size: 12px;
  line-height: 140%;
  &.small {
    margin-top: 4px;
  }
  &.large {
    margin-top: 8px;
  }
}
