@use './mixins.scss';
@import '../../../css/colors';
@import '../../../css/fonts';

.scroller {
  [class*='index-module_track'] {
    opacity: 0.5;
  }
}

.dropdown__helper_text {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  animation: shake-horizontal 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  color: var(--red50);
}
.dropdown-question-disabled {
  opacity: 0.35;
}
.dropdown-wrapper {
  .dropdown__error {
    .select__control {
      border-color: $warning;
    }
  }
  .dropdown__success {
    .select__control {
      opacity: 1;
    }
  }
  .select__indicator-separator {
    display: none;
  }
  label p {
    font-weight: 400;
    font-size: 14px !important;
    line-height: 140% !important;
    color: #444444;
    height: 14px;
    margin-bottom: 8px;
  }
  .select__control {
    box-shadow: none !important;
    border: 1px solid $gray_2;
    border-radius: 8px;
    color: var(--grey90);
    padding: 0 8px;

    height: 100%;
    &.select__control--is-focused {
      border-color: var(--primary-color);
    }
  }
  .input {
    font-family: var(--inter);
    font-size: 18px;
    &.small {
      font-size: 16px;
    }
  }
  .dropdown__helper_text {
    color: $warning;
    position: static;
    margin-top: 8px;
  }
  .bp3-popover-wrapper {
    display: block;
  }
  .bp3-popover-target {
    display: block;
  }
  button {
    display: block;
    width: 100%;
    text-align: left;
  }
  .input.updated-design {
    @include mixins.updated-design-dropdown;
  }
}
.dropdown-fixed {
  max-height: 20em;
  overflow-y: scroll;
  .bp3-menu-item {
    &.selected {
      color: var(--primary-color);
    }
    &:hover {
      background-color: var(--primary-lightest-color);
    }
  }
}
