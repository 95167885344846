.failure-state {
  padding: 0 16px;

  @media (min-width: 480px) {
    padding: 0 24px;
    margin-top: 40px !important;
  }

  &__image {
    display: block;
    width: 100px;
    margin: 20px auto;

    &__hr {
      display: block;
      width: 83px;
      height: 79px;
      margin: 20px auto;
      margin-bottom: 4px;
    }
  }

  &__title {
    font-family: var(--cormorant);
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;

    &__hr {
      font-family: var(--poppins);
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0.0075em;
    }
  }

  &__text {
    font-family: var(--inter);
    font-size: 14px;
    font-weight: 300;
    line-height: 1.5;

    a {
      text-decoration: underline;
      font-weight: 600;
    }
  }

  &__button {
    display: block;
    width: 297px;
    height: 48px;
    border-radius: 8px;
    margin: 24px auto;
    font-family: var(--poppins);
    font-size: 14px;
    font-weight: 500;
    line-height: 20.3px;
    letter-spacing: 0.08em;
    text-align: center;
  }
}

.valid-states {
  .iconfont-check-circle {
    font-size: 1.3em;
    display: block;

    &::before {
      color: #263238;
    }
    &::after {
      color: #0066a6;
    }
  }

  li {
    text-align: left;
    margin-bottom: 10px;
    padding-right: 10px;
    width: 50%;
  }
}
