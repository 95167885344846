@import '../../../../../css/colors';

.tracker_notification {
  border-radius: 8px;
  position: relative;
  margin-top: 26px;
  color: #fff;
  background-size: 47%;
  background: var(--primary-color) url(../../../../../../images/progress-tracker/bottle-bg.png) no-repeat right;
  padding: 24px;
  background-size: 47%;
  z-index: 0;

  &.bg-hospital {
    background-image: url(../../../../../../images/progress-tracker/hospital.png);
  }

  &.updated-design {
    p {
      font-size: 14px;
      font-family: var(--poppins);
      font-weight: 300;
      font-feature-settings: 'ss01' on;
      line-height: 150%;
    }

    &.bg-hospital {
      background-image: none;

      &::before {
        content: '';
        display: block;
        background: url(../../../../../../images/progress-tracker/meds.svg) no-repeat right;
        background-size: contain;
        position: absolute;
        z-index: -10;
        opacity: 0.1;
        top: 50px;
        right: 5px;
        left: 5px;
        bottom: 50px;
      }
    }

    .title_with_icon {
      font-size: 24px;
      font-weight: 700;
      align-items: center;

      .progress_tracker.selectedProduct-warrior & {
        svg {
          path {
            fill: #066c3e;
          }
        }
      }
    }

    .tracker_notification__date {
      top: 30px;
      font-size: 14px;
      font-weight: 500;
      right: 24px;
    }

    .tracker_notification__buttons {
      button {
        height: 40px;
        background: #5b1414;
        border-radius: 5px;
        font-weight: 700;
        font-size: 12px;
        font-family: var(--inter);

        &.bg_white {
          background: #5b1414;
          color: var(--white);
        }

        .progress_tracker.selectedProduct-warrior & {
          background: #087745;
        }
      }

      + .tracker_notification__buttons {
        margin-top: 10px;
      }

      @media (max-width: 480px) {
        gap: 13px;

        button {
          // display: inline-flex;
          flex: 1;
          padding: 0;
        }
      }
    }

    @media (max-width: 480px) {
      padding: 24px 16px;

      .title_with_icon {
        font-size: 22px;
      }

      .tracker_notification__date {
        top: 35px;
      }

      &.bg-hospital::before {
        background-position: center bottom;
        top: 0;
        bottom: 0;
      }
    }
  }

  &.dismissed {
    opacity: 0.5;
    background: #e8e8e8;

    .title_with_icon {
      color: #063654;
    }

    .tracker_notification__description {
      color: #444444;
    }

    .tracker_notification__date {
      color: #000;
    }
  }

  > * {
    font-family: Inter;
  }

  &__description {
    margin-top: 16px;
    line-height: 24px;

    i {
      font-style: italic;
    }
  }

  .tracker_notification__buttons {
    display: flex;
    margin-top: 20px;
    gap: 8px;

    & > * {
      flex: 1 0 50%;
    }
  }

  .tracker_notification__date {
    position: absolute;
    top: 15px;
    right: 10px;
    font-size: 12px;
  }

  button {
    background-color: #053d62;
    font-size: 14px;
    letter-spacing: 1px;

    &.bg_white {
      background-color: #fff;
      color: #053d62;
    }
  }
}

.tracker_notification_alarm {
  background-color: $warning;
  padding: 18px;
  margin-top: 16px;
  border-radius: 7px;
  font-size: 14px;

  .flex {
    gap: 16px;
  }
}

.new-design {
  h4.date-format {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  h2.title-format {
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  p.paragraph-format {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  a.links-format {
    color: #2e4cff;
    margin: 5px 0;

    &:hover {
      color: #1a34cc;
      text-decoration: underline;
    }
  }
}
