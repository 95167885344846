.lover-intro {
  h1 {
    font-size: 40px;
    position: relative;
    margin-bottom: 20px;
    font-weight: 700;
    margin-top: 40px;
    span {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        background: url('images/i-title-products-lover.svg') center no-repeat;
        background-size: contain;
        width: 22px;
        height: 29px;
        top: -14px;
        left: -5px;
      }
    }
  }
  sup {
    font-size: 10px;
  }
  button {
    border-radius: 8px;
  }
  a:hover {
    text-decoration: underline;
  }
}
