@use 'sass:list';
//  Animate.css: base
@use 'node_modules/animate.css/source/_vars.css';
//@use "node_modules/animate.css/source/_base.css";

// Fading entrances
@use 'node_modules/animate.css/source/fading_entrances/fadeIn.css';
@use 'node_modules/animate.css/source/fading_entrances/fadeInDown.css';
@use 'node_modules/animate.css/source/fading_entrances/fadeInDownBig.css';
@use 'node_modules/animate.css/source/fading_entrances/fadeInLeft.css';
@use 'node_modules/animate.css/source/fading_entrances/fadeInLeftBig.css';
@use 'node_modules/animate.css/source/fading_entrances/fadeInRight.css';
@use 'node_modules/animate.css/source/fading_entrances/fadeInRightBig.css';
@use 'node_modules/animate.css/source/fading_entrances/fadeInUp.css';
@use 'node_modules/animate.css/source/fading_entrances/fadeInUpBig.css';

// Fading exits
@use 'node_modules/animate.css/source/fading_exits/fadeOut.css';
@use 'node_modules/animate.css/source/fading_exits/fadeOutDown.css';
@use 'node_modules/animate.css/source/fading_exits/fadeOutDownBig.css';
@use 'node_modules/animate.css/source/fading_exits/fadeOutLeft.css';
@use 'node_modules/animate.css/source/fading_exits/fadeOutLeftBig.css';
@use 'node_modules/animate.css/source/fading_exits/fadeOutRight.css';
@use 'node_modules/animate.css/source/fading_exits/fadeOutRightBig.css';
@use 'node_modules/animate.css/source/fading_exits/fadeOutUp.css';
@use 'node_modules/animate.css/source/fading_exits/fadeOutUpBig.css';

// Sliding entrances
@use 'node_modules/animate.css/source/sliding_entrances/slideInDown.css';
@use 'node_modules/animate.css/source/sliding_entrances/slideInLeft.css';
@use 'node_modules/animate.css/source/sliding_entrances/slideInRight.css';
@use 'node_modules/animate.css/source/sliding_entrances/slideInUp.css';

// Sliding exits
@use 'node_modules/animate.css/source/sliding_exits/slideOutDown.css';
@use 'node_modules/animate.css/source/sliding_exits/slideOutLeft.css';
@use 'node_modules/animate.css/source/sliding_exits/slideOutRight.css';
@use 'node_modules/animate.css/source/sliding_exits/slideOutUp.css';

$directions: ('Down' 'Left' 'Right' 'Up');
$directionsFade: ('' 'DownBig' 'LeftBig' 'RightBig' 'UpBig');

@each $type in ('In' 'Out') {
  @each $side in list.join($directions, $directionsFade) {
    @debug fade#{$type}#{$side}Deep;

    .fade#{$type}#{$side}Deep.animated-deep,
    .fade#{$type}#{$side}Deep .animated-deep {
      @extend .fade#{$type}#{$side};
    }
  }

  @each $side in $directions {
    .slide#{$type}#{$side}Deep.animated-deep,
    .slide#{$type}#{$side}Deep .animated-deep {
      @extend .slide#{$type}#{$side};
    }
  }
}

.animated-deep {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.transitioning[class*='Out'] .animated-deep-hidden {
  visibility: hidden;
}
