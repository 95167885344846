@import '../../../../css/colors';

.progress_tracker__resources {
  border-bottom: 1px solid #e1e1e3;
  a {
    text-decoration: underline;
    &:hover {
      color: var(--primary-color);
    }
  }
  &__flex {
    display: flex;
    gap: 40px;
    margin-top: 60px;
    @media (max-width: 479px) {
      padding-bottom: 20px;
      gap: 0px;
    }
  }
  .resource {
    padding-bottom: 10px;
    img {
      width: 100%;
    }
    .resource-content {
      @media (max-width: 479px) {
        padding: 0 16px 19px 16px;
      }
    }
    @media (max-width: 479px) {
      box-shadow:
        0px 4px 8px rgba(58, 58, 68, 0.12),
        0px 8px 16px rgba(90, 91, 106, 0.12);
      border-radius: 6px;
    }
  }
}

.resource__heading {
  font-size: 24px;
  font-weight: bold;
  font-family: Cambon;
  padding-top: 15px;
  @media (max-width: 479px) {
    font-size: 18px;
  }
}

.resource__description {
  margin: 19px 0;
  @media (max-width: 479px) {
    font-size: 14px;
  }
}
