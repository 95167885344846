@import '../../../../css/colors';
.checkpoint__background {
  font-family: Inter, sans-serif;
  background-color: #f7f7fa;
  padding: 22px 16px;
  color: $gray_text;
  border-top: 1px solid #e1e1e3;
  margin-top: 19px;
  .required {
    color: #b51f2c;
  }
  .gray {
    color: #9a9a9a;
  }
  &__title {
    font-size: 18px !important;
    color: #3a3a44;
    margin-bottom: 8px;
  }
  &__item {
    display: flex;
    gap: 14px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e1e1e3;
    margin-bottom: 20px;

    &:last-child {
      padding-top: 20px;
      margin-bottom: 0;
    }
  }
}

.checkpoint__title {
  font-family: OpenSans;
  font-size: 34px;
}
