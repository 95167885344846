.list-item {
  margin-left: 1.2em;
  margin-bottom: 0.5em;

  &::before {
    content: '•';
    color: black;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
