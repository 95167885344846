@import '../../admin/css/common.scss';

[class*='-overlay-content'] {
  z-index: 2000;
}
.profile-menu {
  color: white;
  display: flex;
  align-items: center;
  column-gap: 10px;
  > *:not(:last-child) {
    border-right: 1px solid #2e2e2e;
    padding-right: 10px;
  }
  &-user {
    display: flex;
    column-gap: 10px;

    &_info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &_role {
      font-size: 10px;
      color: #aaaaaa;
      text-align: right;
    }
    &.bp3-active &_role {
      color: #2a91d1;
    }
    &_name {
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
    &_avatar {
      svg {
        vertical-align: top;
      }
      &-notification {
        position: relative;
        &:before {
          position: absolute;
          right: 2px;
          top: 2px;
          content: '';
          height: 8px;
          width: 8px;
          background-color: #ff0000;
          border-radius: 8px;
        }
      }
    }
    &.bp3-active &_avatar {
      svg circle:first-child {
        fill: $primary-color;
      }
    }
  }
  &__notification {
    position: relative;
    &:before {
      position: absolute;
      left: 22px;
      top: 12px;
      content: '';
      height: 8px;
      width: 8px;
      background-color: #ff0000;
      border-radius: 8px;
    }
  }
  .profile-menu_item {
    cursor: pointer;
    position: relative;

    &:active {
      .profile-menu_popup {
        display: flex;
        flex-direction: column;
      }
    }

    > svg {
      margin: auto 10px;
    }
    &:not(.profile-menu-user) {
      border-radius: 4px;
      padding: 8px;
      &.bp3-active {
        background-color: rgba(199, 199, 199, 0.35);
      }
    }
    &.bp3-active.profile-menu-user {
      > svg path {
        fill: #2a91d1;
      }
    }
  }
  &_popup-menu {
    max-height: 80vh;
    overflow: auto;
    box-shadow: var(--shadow8dp);
    padding: 0;

    li {
      border-bottom: 1px solid $lightgrey-color;

      font-size: 14px;
      color: #757575;
      a {
        padding: 10px;
        height: 50px;
        display: flex;
        align-items: center;
      }
      img {
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }
    }
  }
}
