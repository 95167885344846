.persona-widget {
  min-height: 720px;

  @media (max-width: 480px) {
    flex: 1;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}

.new-theme {
  .persona-widget {
    iframe {
      min-height: 720px;
    }
  }
}
