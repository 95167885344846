@import '../../../css/colors';

.referral-source-modal.adk-discontinuation {
  .referral-source-modal {
    &__title,
    &__content,
    &__content p {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 19.6px;
      font-family: var(--inter);
    }

    &__content p {
      padding-bottom: 25px;
    }
  }

  .author {
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    gap: 25px;

    &__image {
      background: url('images/bg-cam-adk-notification.png') center no-repeat;
      background-size: contain;
      width: 80px;
      height: 90px;
    }

    &__text {
      font-size: 14px;

      b {
        font-weight: 700;
      }
    }
  }

  .bp3-icon.bp3-icon-cross svg {
    fill: #c7c7c7 !important;
  }

  .primary-button {
    max-width: 370px;
    margin: 0px auto;
    display: block;
  }
}
