/*FLAT STYLE*/
.flat,
.flat[class*='button'],
.flat:visited {
  text-transform: uppercase;
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.flat:hover,
.flat[class*='button']:hover,
.flat:hover {
  text-transform: uppercase;
  color: var(--maximus);
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.flat:active,
.flat[class*='button']:active,
.flat[class*='button'][class*='active'],
.flat[class*='button'].focused {
  text-transform: uppercase;
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.flat[class*='disabled']:disabled,
.flat[class*='disabled']:disabled > span > svg,
.flat[class*='disabled']:disabled:hover,
.flat[class*='disabled']:disabled:active {
  /* color: var(--color30); */
  text-transform: uppercase;
  opacity: 0.35;
  /* background-color: var(--color0); */
  background: none;
  box-shadow: none;
}
/*END STYLE*/

/* FILLED STYLE*/
.filled[class*='button'],
.filled[class*='button']:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  text-transform: uppercase;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.filled[class*='button']:hover {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
  text-transform: uppercase;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.filled[class*='button']:active,
.filled[class*='button'][class*='active']:active,
.filled[class*='button'].focused {
  background: var(--color80);
  text-transform: uppercase;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.filled[class*='disabled']:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  text-transform: uppercase;
  opacity: 1;
}
/* END STYLE */

/*SMOOTH STYLE*/
.smooth[class*='button'],
.smooth[class*='button']:visited {
  text-transform: uppercase;
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.smooth[class*='button']:hover {
  text-transform: uppercase;
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.smooth[class*='button']:active,
.smooth[class*='button']:active,
.smooth[class*='button'][class*='active'],
.smooth[class*='button'].focused {
  text-transform: uppercase;
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.smooth[class*='disabled']:disabled,
.smooth[class*='disabled']:disabled:hover {
  text-transform: uppercase;
  color: var(--color30);
  background-color: var(--color0);
  opacity: 1;
}
/* END STYLE */

/* OUTLINED STYLE*/
.outlined[class*='button'],
.outlined[class*='button']:visited {
  text-transform: uppercase;
  background: none;
  box-shadow: 0 0 0 1px var(--color30);
  border: 1px solid var(--color50);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.outlined[class*='button']:hover {
  text-transform: uppercase;
  box-shadow: 0 0 0 2px var(--color30);
  width: calc(- 2px);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.outlined[class*='button']:active,
.outlined[class*='button'].focused {
  text-transform: uppercase;
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.outlined[class*='disabled']:disabled {
  text-transform: uppercase;
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
/* END STYLE */

/* RAISED STYLE */
.raised[class*='button'],
.raised[class*='button']:visited {
  text-transform: uppercase;
  background: var(--color100);
  color: var(--white);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.raised[class*='button']:hover {
  text-transform: uppercase;
  background: var(--color90);
  color: var(--white);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.raised[class*='button']:active,
.raised[class*='button'].focused {
  text-transform: uppercase;
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.raised[class*='disabled']:disabled {
  text-transform: uppercase;
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
/* END STYLE */
