.refill_complete__success {
  box-shadow:
    0px 2px 4px rgba(58, 58, 68, 0.1),
    0px 4px 8px rgba(90, 91, 106, 0.1) !important;
  padding: 16px 16px 26px 16px;
  margin-bottom: 28px;
  img {
    width: 28px;
    margin-right: 10px;
  }
}

.refill_complete__info {
  margin-top: 16px;
  margin-bottom: 18px;
}

.refill_complete__heading {
  font-size: 14px;
}

.refill_complete__question {
  font-size: 20px !important;
}

.refill_complete {
  &__questions {
    strong {
      font-weight: 600;
    }

    ul {
      list-style-type: disc;
    }

    li {
      color: var(--text-color);
      line-height: 1.5;
    }
  }

  textarea::placeholder {
    font-weight: bold;
  }
}
