.coupon-code-input {
  margin-left: 0px !important;
  width: 100%;

  &__button {
    color: rgba(0, 102, 166, 1);
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.0075em;
    text-decoration: underline;
    text-align: left;
    padding: 0px;
    border: none;
    background: none;
    cursor: pointer;

    &__apply {
      font-size: 14px;
      line-height: 21px;
      position: absolute;
      position: absolute;
      top: 0;
      height: 100%;
      right: 0;
    }
  }

  &__error {
    color: #ff3333;
    margin-top: 8px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .hs2-text-input__state {
    display: none;
  }
  .hs2-text-input__input {
    padding-right: 64px !important;
  }
  .hs2-text-input__input.valid {
    border-color: #e1e1e1;
  }
}

.manage_subscription__column {
  .coupon-code-input {
    &__button {
      padding-left: 0 !important;
    }
  }
}
