@import '../../admin/css/common.scss';

.subheader {
  line-height: 28px;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid #e9e9e9;

  &_products {
    padding: 0px 16px;

    @media (max-width: 600px) {
      padding: 0px;
    }
  }

  &__content,
  &__page-info,
  &__breadcrumbs {
    display: flex;
    align-items: center;
  }

  &__content {
    max-width: 1520px;
    margin: 0 auto !important;
    justify-content: space-between;
  }

  &__page-info {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    line-height: 18px;
    text-transform: uppercase;
    display: flex;
    flex: 1 1;
    .title {
      width: 100%;
      @media (max-width: 480px) {
        text-align: center;
      }
    }
    // TODO: hover states, focus rings and stuff
    svg {
      transform: rotate(-180deg);
      margin-right: 10px;
    }
  }

  &__breadcrumbs {
    list-style: none;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 12px;

    @media (max-width: 480px) {
      display: none;
    }
    svg {
      margin: 0 10px;
    }
    // TODO: hover states, focus rings and stuff
    li {
      display: flex;
      align-items: center;
      &:first-child {
        svg {
          display: none;
        }
      }
      .item {
        color: #4a4b57;
        cursor: pointer;
        &.active {
          color: var(--primary-color);
          cursor: default;
        }
      }
    }
  }
}
