/*RADIO*/

/*FLAT*/
.flat {
  color: var(--color100);
}
.flat > label[class*='control'] span[class*='indicator'] {
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
.flat:hover:not(.disabled) > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--color5);
  box-shadow: 0 0 0 0.625rem var(--color5);
  transition: box-shadow ease 150ms;
}
.flat[class*='dense']:hover > label[class*='control'] > span[class*='indicator'],
.flat.checked[class*='dense'] > label[class*='control'] > span[class*='indicator'] {
  box-shadow: 0 0 0 0.375rem var(--color5);
  background-color: var(--color5);
}
.flat.checked {
  background: var(--color5);
  transition: background ease 250ms;
}
.flat[class*='dense'].checked {
  background: unset;
  transition: background ease 250ms;
}
.flat.checked input:checked ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transition:
    transform ease 250ms,
    background-color ease 250ms;
}
.flat:hover input:checked ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
}
.flat input:not(:checked) ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
.flat.disabled > label[class*='control'] > div {
  color: var(--color40);
}
.flat.checked.disabled > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--color5);
}
.flat.disabled:hover > label[class*='control'] > span[class*='indicator'] {
  background-color: initial;
  box-shadow: none;
}
.flat[class*='dense'].checked.disabled > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--color5);
  box-shadow: 0 0 0 0.375rem var(--color5);
}
/*helper*/
.flat .helperText,
.flat.disabled .helperText {
  color: var(--color60);
}
/*-----------*/

/*SMOOTH*/
.smooth {
  color: var(--color100);
  background: var(--color0);
}
.smooth:hover {
  background: var(--color5);
  transition: background ease 250ms;
}
.smooth.checked {
  background: var(--color10);
  transition: background ease 250ms;
}
.smooth > label[class*='control'] span[class*='indicator'] {
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
.smooth:hover:not(.disabled) > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--color5);
  box-shadow: none;
  transition: all ease 150ms;
}
.smooth.checked > label[class*='control'] > span[class*='indicator'],
.smooth.checked:hover > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--color10);
  transition: all ease 150ms;
}

.smooth.checked input:checked ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transition:
    transform ease 250ms,
    background-color ease 250ms;
}
.smooth:hover input:checked ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
}
.smooth input:not(:checked) ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
.smooth.disabled > label[class*='control'] > div {
  color: var(--color40);
}
.smooth.checked.disabled,
.smooth.disabled:hover,
.smooth.checked.disabled > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--color0);
}
.smooth.disabled.checked > label[class*='control'] > span[class*='indicator'] {
  background-color: initial;
  box-shadow: none;
}
/*helper*/
.smooth .helperText,
.smooth.disabled .helperText {
  color: var(--color60);
}
/*-----------*/

/*RAISED*/
.raised {
  color: var(--black);
  background: var(--color0);
  box-shadow: var(--shadow2dp);
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0, 0);
}
.raised:hover {
  background: var(--white);
  box-shadow: var(--shadow4dp);
  transition: background ease 250ms;
}
.raised.checked {
  background: var(--color5);
  box-shadow: var(--maximusShadow4dp);
  transition: background ease 250ms;
  border: 1px solid var(--color70);
}
.raised > label[class*='control'] span[class*='indicator'] {
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
.raised:hover:not(.disabled) > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
.raised.checked > label[class*='control'] > span[class*='indicator'],
.raised.checked:hover > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--color5);
  transition: all ease 150ms;
}

.raised.checked input:checked ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transition:
    transform ease 250ms,
    background-color ease 250ms;
}
.raised:hover input:checked ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
}
.raised input:not(:checked) ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
.raised.disabled > label[class*='control'] > div {
  color: var(--color40);
}
.raised.checked.disabled,
.raised.disabled:hover,
.raised.checked.disabled > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--color0);
  box-shadow: var(--shadow2dp);
}
.raised.disabled.checked > label[class*='control'] > span[class*='indicator'] {
  background-color: initial;
  box-shadow: none;
}
/*helper*/
.raised .helperText,
.raised.disabled .helperText {
  color: var(--color60);
}
/*-----------*/

/*OUTLINED*/
.outlined {
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 0.0625rem var(--color20);
}
.outlined:hover {
  box-shadow: 0 0 0 0.0625rem var(--color40);
  transition: all ease 250ms;
}
.outlined.checked {
  box-shadow: 0 0 0 0.125rem var(--color40);
  background: var(--white);
  transition: all ease 250ms;
}
.outlined > label[class*='control'] span[class*='indicator'] {
  background: var(--white);
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
.outlined:hover:not(.disabled) > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
.outlined.checked > label[class*='control'] > span[class*='indicator'] {
  background-color: var(--white);
  background: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}

.outlined.checked input:checked ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transition:
    transform ease 250ms,
    background-color ease 250ms;
}
.outlined:hover input:checked ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
}
.outlined input:not(:checked) ~ span[class*='indicator']::before {
  background-image: radial-gradient(var(--color70), var(--color70) 28%, transparent 32%);
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
.outlined.disabled > label[class*='control'] > div {
  color: var(--color40);
}
.outlined.checked.disabled,
.outlined.disabled:hover,
.outlined.checked.disabled > label[class*='control'] > span[class*='indicator'] {
  box-shadow: 0 0 0 0.0625rem var(--color10);
}
.outlined.disabled.checked > label[class*='control'] > span[class*='indicator'] {
  background-color: initial;
  box-shadow: none;
}
/*helper*/
.outlined .helperText,
.outlined.disabled .helperText {
  color: var(--color60);
}
/*-----------*/

/*HELPER TEXT*/
/*.helper div {
  color: var(--grey50);
}
.helper.disabled div {
  color: var(--grey40);
}*/
