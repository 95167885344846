.footer {
  grid-area: footer;
  border-top: 1px solid #e1e1e1;
  padding: 16px 16px;
  align-items: center;
  font-family: Lato !important;
  background: #ffffff;
  @media (max-width: 480px) {
    display: none;
  }
}

.footer__content {
  align-items: center;
  max-width: 1520px;
  margin: 0 auto;
  display: flex;
  font-size: 12px;
  @media (max-width: 1680px) {
    padding: 0px 70px;
  }
  &__logo {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  &__links {
    margin-left: auto;
    display: flex;
    gap: 32px;

    a {
      color: #444;
    }
  }
}
