.account-settings {
  flex: 1;
  position: relative;

  .page-header {
    box-shadow: none;
  }

  h6 {
    margin: 0 !important;
    font-size: 18px;
  }
  .title_with_line {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .tiles-container {
    position: relative;
    max-width: 456px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 50px;
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media (min-width: 1280px) {
      padding: 0;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .tile {
      padding: 20px;
      background: #ffffff;
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: Inter, sans-serif;
      font-weight: 400;
      font-size: 12px;
      &_notification {
        border: 1px solid #ff0000;
      }
      img {
        width: 100px;
        height: 100px;
      }
      &__contents {
        padding: 10px 0;
        min-height: 100px;
        line-height: 19px;
        font-size: 14px;
      }
      button {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 12px;
        border-radius: 4px !important;
        padding: 10px 20px !important;
        height: 45px;
        width: 95%;
        text-transform: uppercase;
        &.warning-button {
          background-color: #ff9f10;
          border: 1px solid #ff9f10 !important;
          color: #fff;

          &:hover {
            opacity: 0.7;
            border-color: #fbcc86;
          }
        }

        &.danger-button {
          background-color: #c8372d;
          border: 1px solid #c8372d !important;
          color: #fff;

          &:hover {
            opacity: 0.7;
            border-color: #d57e78;
          }
        }

        &.pink-button {
          background-color: #fd8670;
          border: 1px solid #fd8670 !important;
          color: #fff;

          &:hover {
            opacity: 0.7;
            border-color: #fc9885;
          }
        }
      }
    }
  }
}

.password-settings {
  margin: 50px 0px;

  &__success {
    display: none;
    background: #32ba7c;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    padding: 20px 0px;
    text-transform: uppercase;
  }

  &__error {
    background: #ff3333;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    padding: 20px 0px;
    text-transform: uppercase;
  }

  &__info {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 30px;
  }

  &__box {
    background-color: #fff;
    box-shadow:
      0px 2px 4px rgba(58, 58, 68, 0.1),
      0px 4px 8px rgba(90, 91, 106, 0.1);
    border-radius: 4px;
    padding: 30px 33px;

    .flex {
      gap: 33px;
    }

    button {
      border-radius: 4px !important;
    }

    .default-button {
      height: 45px;
    }

    hr {
      background-color: #d9d9d9;
      height: 1px;
      border: none;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    &-success {
      .password-settings {
        &__inputs {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background: rgba(255, 255, 255, 0.5);
          }
        }

        &__success {
          display: block;
        }
      }
    }
  }
}

.bg-gray {
  position: absolute;
  background-color: #f6f8fa;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.color-gray {
  color: #9d9d9d;
}
