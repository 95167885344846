@import '../../../css/colors';
@mixin checkbox-size($size) {
  span.bp3-control-indicator {
    background-color: white !important;
  }
  ._type-module__box_layout___2OVUF._view-module__box_layout___3zyRw {
    height: $size + 22px;
    width: $size + 22px;
  }
  &._view-module__raised___1E0mx._view-module__checked___Ev_Vd {
    background-color: var(--primary-lightest-color) !important;
    border-color: var(--primary-color) !important;
    span[class*='control-indicator'] {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }
  }
  &._type-module__def___2zWvD._view-module__flat___w6G8F.color-module__default___17De6.color-module__select___18uA- {
    span[class*='control-indicator'] {
      height: $size * 1px;
      border-color: var(--primary-color) !important;
      width: $size * 1px;
      margin: 12px;
      &::before {
        height: $size * 1px;
        width: $size * 1px;
      }
    }
  }
  &._view-module__raised___1E0mx[class*='control'] span[class*='control-indicator'] {
    border-color: var(--primary-color) !important;
  }
  &._view-module__flat___w6G8F._view-module__checked___Ev_Vd {
    span[class*='control-indicator'] {
      background-color: var(--primary-color) !important;
      height: $size * 1px;
      width: $size * 1px;
      margin: 12px;
      &::before {
        height: $size * 1px;
        width: $size * 1px;
      }
    }
  }
}
.blue-checkbox {
  &.small {
    @include checkbox-size(30);
  }
  &.large {
    @include checkbox-size(52);
  }
}
.hs2-checkbox__checkbox {
  display: block;
  cursor: pointer;
}
.hs2-checkbox__children {
  margin-left: 12px;
  .label + .sublabel {
    margin-top: -8px;
  }
}
.hs2-checkbox--disabled {
  opacity: 0.36;
}
.hs2-checkbox__checkbox--small {
  width: 16px;
  height: 16px;
}
.hs2-checkbox__checkbox--large {
  width: 24px;
  height: 24px;
}
.hs2-checkbox__input {
  display: none;
}
.hs2-checkbox--contained {
  background-color: white;
  box-shadow:
    0px 4px 8px rgba(58, 58, 68, 0.12),
    0px 8px 16px rgba(90, 91, 106, 0.12);
  border-radius: 8px;
  padding: 5px 16px;
}
.hs2-checkbox--contained.hs2-checkbox--checked {
  outline: 1px solid var(--primary-color);
  background-color: var(--primary-lightest-color);
}

.hs2-checkbox label {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  &.top-aligned {
    align-items: start;
  }
}
