.left-side {
  position: absolute;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-right: 2px solid black;
  background-color: black;
  z-index: 4;
  a:hover {
    text-decoration: none;
  }
  .element {
    cursor: pointer;
    h6 {
      color: white;
    }
  }
  .end-elements {
    margin-top: auto;
  }
}
.right-side {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
