.back-button {
  color: #cea249;
  background-color: #f6f8fa;
  border: 1px solid #cea249;
}
.back-next.updated-design {
  .updated-design-button {
    font-weight: 700;
    font-family: var(--lato);
  }
  .updated-design-button.outlined-button {
    border-color: var(--primary-color);
    color: var(--primary-color);
  }
}
