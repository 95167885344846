@import '../../../css/colors';

.page-header {
  display: flex;
  background-color: var(--white);
  box-shadow:
    0 4px 8px rgba(90, 91, 106, 0.2),
    0 2px 4px rgba(58, 58, 68, 0.2);
}
.updated-design {
  .page-header {
    --font_size_h6: 24px;
    --font_weight_h6: 700;
    display: block;
    box-shadow: none;
    position: relative;

    &.-edged {
      h6::before {
        content: '\00a0';
        display: inline-block;
        border-left: 4px solid var(--primary-color);
      }
    }

    h6 {
      font-family: var(--lato);
      box-shadow:
        0 2px 4px rgba(58, 58, 68, 0.1),
        0 4px 8px rgba(90, 91, 106, 0.1);
      margin: 0 auto;

      background-color: var(--white);
      justify-self: center;
      align-self: center;
      display: flex;
      padding: 12px 24px;
      align-items: center;
      gap: 12px;

      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-width: 0;

      .icon {
        width: 32px;
        height: 32px;

        color: #3a3a44;
        display: flex;
        align-items: center;
        justify-content: center;

        &.-rounded {
          border: 2px solid var(--primary-color);
          border-radius: 100%;
        }

        svg {
          height: 20px;
          width: 20px;
        }
      }

      .title {
        flex: 1;
      }
    }

    @media (max-width: 480px) {
      height: auto;
      h6 {
        position: relative;
        padding: 10px 16px;
        border-radius: 0;
      }
    }
  }
}

.edge-left {
  border-left: 4px solid var(--primary-color);
}
