@import 'variables';
@import 'breakpoints';
@import 'grid';
@import 'nudge';
@import 'utils';
@import 'font_faces';
@import 'fonts';
@import 'animate';

@tailwind base;
@tailwind components;
@tailwind utilities;

@mixin subheader {
  display: block;
  font-size: 75%;
  opacity: 0.75;
}
.bgpink {
  background-color: pink;
}
.herman-main {
  background-repeat: no-repeat;
  background-position: 0 -2000px;
  background-size: cover;
  background-image: url(../../images/Blue-Curve.svg);
}
.herman_header {
  color: white;
}
.body {
  height: 100%;
  background-color: #f6f8fa;
  touch-action: manipulation;
}
.h100 {
  height: 100%;
}
.hs-body {
  height: 100%;
  background-color: white;
}
.admin-grid-container #sidebar {
  width: 1000px;
}
.admin-grid-container .header__content {
  min-width: 100%;
}
#sidebar {
  z-index: 3;
  position: fixed; /* or choose `absolute` depending on desired behavior*/
  top: 0;
  bottom: 0;
  width: 350px;
  transition: transform 0.3s ease-in-out;
}
#sidebar.collapsed {
  transform: translateX(0%);
  left: -1000px;
}
:not(.select__menu-list, .admin-menu_popup-menu, .dropdown-fixed) {
  &::-webkit-scrollbar {
    width: 0;
  }
}
ul {
  list-style-type: none;
}
.bp3-card {
  padding: 0;
  display: flow-root;
}
.flow-root {
  display: flow-root;
}
.stripe-card-number,
.stripe-card-expiry,
.stripe-card-cvc {
  color: lightgrey;
  background-color: white;
}
.bp3-card.danger-card {
  border: 1px solid var(--red60);
}
[data-react-class='App'] {
  height: 100%;
  width: 100%;
}
.trigger_test_error {
  position: fixed;
  bottom: 0;
  right: 0;
  opacity: 0;
}
.apart {
  display: flex;
  > div:first-child {
    flex: 1;
  }
}
.flexAlignCenter {
  align-items: center;
}
.flexAlignBaseLine {
  align-items: baseline;
}
.flexAlignEnd {
  align-items: end;
}
.flexJustifyEnd {
  justify-content: end;
}
.cWhite {
  color: white;
}

.cPink {
  color: pink;
}
.mhauto {
  margin-left: auto;
  margin-right: auto;
}

.hs2-flex-hcenter {
  display: flex;
  justify-content: center;
}
.hs2-desktop-bounds {
  max-width: 680px;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
}
.font-medium {
  font-weight: 500;
}
.font-poppins {
  font-family: var(--poppins);
}
.relative {
  position: relative;
}
@media (max-width: 479px) {
  .mobile2desktop1 > div:last-child {
    margin-top: 20px;
  }
}
@media (min-width: 480px) {
  .mobile2desktop1 {
    display: flex;
  }
  .mobile2desktop1 > div {
    flex: 1;
  }
  .mobile2desktop1 > div:last-child {
    margin-left: 12px;
  }
}

// Fix popover2 arrows (blueprintjs upgraded needed)
.bp3-popover2-arrow {
  visibility: hidden;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
