@for $i from 0 through 20 {
  $size: $i * 4;
  .p#{$size} {
    padding: #{$size}px;
  }
  .ph#{$size} {
    padding-left: #{$size}px;
    padding-right: #{$size}px;
  }
  .pv#{$size} {
    padding-top: #{$size}px;
    padding-bottom: #{$size}px;
  }
  .pl#{$size} {
    padding-left: #{$size}px;
  }
  .pr#{$size} {
    padding-right: #{$size}px;
  }
  .pt#{$size} {
    padding-top: #{$size}px;
  }
  .pb#{$size} {
    padding-bottom: #{$size}px;
  }
  .m#{$size} {
    margin: #{$size}px;
  }
  .mh#{$size} {
    margin-left: #{$size}px;
    margin-right: #{$size}px;
  }
  .mv#{$size} {
    margin-top: #{$size}px;
    margin-bottom: #{$size}px;
  }
  .ml#{$size} {
    margin-left: #{$size}px;
  }
  .mr#{$size} {
    margin-right: #{$size}px;
  }
  .mt#{$size} {
    margin-top: #{$size}px;
  }
  .mb#{$size} {
    margin-bottom: #{$size}px;
  }
}

.mhauto {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
@for $i from 4 through 14 {
  $size: $i * 8;
  .h#{$size} {
    height: #{$size}px;
    line-height: #{$size}px;
  }
}
@media (max-width: 479px) {
  .mt1224 {
    margin-top: 12px;
  }
  .mb1224 {
    margin-bottom: 12px;
  }
}
@media (min-width: 480px) {
  .mt1224 {
    margin-top: 24px;
  }
  .mb1224 {
    margin-bottom: 24px;
  }
}
