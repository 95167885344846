@import '../../../css/colors';

@mixin expand-button-small {
  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  button {
    font-size: 16px;
  }
}

@mixin expand-button-large {
  button {
    font-size: 20px;
  }
  svg {
    cursor: pointer;
    width: 22px;
    height: 20px;
  }
}

.expand-button__container {
  display: flex;
  &.small {
    @include expand-button-small;
  }
  &:hover,
  &.hover {
    button {
      color: var(--primary-lighten-color);
    }
  }
  &:active,
  &.active {
    button {
      color: var(--primary-darken-color);
    }
  }
  &.disabled {
    opacity: 0.35;
  }
  &.large {
    @include expand-button-large;
  }
}
.expand-button {
  cursor: pointer;
  padding: 0;
  border: 0;
  text-align: left;
  font-family: Cambon;
  font-weight: bold;
  background-color: transparent;
  font-size: 12px;
  color: var(--black);
  &.open {
    color: var(--primary-lighten-color);
  }
  &:disabled {
    opacity: 0.35;
    cursor: default;
  }
  &__content {
    margin-top: 20px;
  }
}

.expand-button__flex {
  display: flex;
  gap: 24px;
}

.expand-button__icon {
  cursor: pointer;
  width: 19px;
  flex-shrink: 0;
}

@media (max-width: 479px) {
  .expand-button__container {
    @include expand-button-small;
  }
}

@media (min-width: 480px) {
  .expand-button__container {
    @include expand-button-large;
  }
}
