.silver-plan {
  background: linear-gradient(180deg, #c9c9c9 100%, #878787 100%) !important;
  color: white !important;
  border: none !important;
  box-shadow:
    rgba(90, 91, 106, 0.16) 0px 8px 16px 0px,
    rgba(58, 58, 68, 0.16) 0px 4px 8px 0px !important;
}

.gold-plan {
  background: linear-gradient(180deg, #efc267 100%, #d19926 100%) !important;
  color: white !important;
}
