@import '../../../css/colors';
@import '../../../css/fonts';

$updated-design-input-height: 45px;
$updated-design-input-font-size: 14px;
$updated-design-input-border-color: #d9d9d9;
$updated-design-input-focused-border-color: var(--primary-color);
$updated-design-input-color: #19191d;

@mixin text-input {
  & {
    border: 1px solid $gray_2;
    box-sizing: border-box;
    border-radius: 8px;
    color: var(--grey90);
    width: 100%;
    padding: 16px;
    font-size: 18px;
    font-family: var(--inter);
  }

  &::placeholder {
    color: rgba(25, 25, 29, 0.65);
  }
  &:focus,
  &.focus {
    border-color: var(--primary-color);
  }
  &.valid {
    border-color: #37833b;
    padding-right: 53px;
  }
  &.failed {
    border-color: $warning;
    padding-right: 53px;
  }
  &:disabled {
    background: $gray_1;
    color: $gray_4;
    opacity: 0.35;
  }
  &.small {
    font-size: 16px;
  }
}
@mixin updated-design-input-border {
  & {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
  }
}

@mixin updated-design-input {
  @include text-input;
  & {
    font-weight: 500;
    font-size: $updated-design-input-font-size;
    font-feature-settings:
      'tnum' on,
      'lnum' on,
      'ss01' on;
    padding: 15px;
    color: $updated-design-input-color;
    height: $updated-design-input-height;
  }
  @include updated-design-input-border;
  &:focus,
  .focus {
    border-color: $updated-design-input-focused-border-color;
  }
}
@mixin updated-design-dropdown {
  font-weight: 500;
  color: #19191d;
  &.medium {
    height: $updated-design-input-height;
    font-size: $updated-design-input-font-size;
    font-feature-settings:
      'tnum' on,
      'lnum' on,
      'ss01' on;
    .select__indicator {
      padding-right: 4px;
    }
  }
  .select__control {
    @include updated-design-input-border;
    & {
      color: $updated-design-input-color;
    }
    .select__single-value {
      color: $updated-design-input-color;
    }
    &--is-focused {
      border-color: $updated-design-input-focused-border-color;
    }
  }
}

@mixin updated-design-button {
  font-size: 18px;
  border-radius: 8px;
  height: 65px;
  font-family: var(--inter);
  font-weight: 400;
  &.primary-button {
    font-weight: 600;
  }
  &.outlined-button {
    color: #19191d;
    border-color: #f0f0f0;
  }
}
