.safety-modal {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: hidden;
}

.safety-modal-content {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 10px;
}

.safety-modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0px -2px 10px 4px rgba(0, 0, 0, 0.15);
  padding: 20px 0;
}

.flex-container {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

ol.list-style-type {
  list-style: decimal !important;
  padding-left: 30px !important;
  margin: 1em 0 !important;
}
