@import '../../../css/colors';

.popup-modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.55); /* Black w/ opacity */

  &-show {
    display: block !important;
  }
  &.mobile {
    .popup-modal__content {
      min-width: 100%;
      position: absolute;
      display: block;
      bottom: 0;
      border-radius: 16px 16px 0 0;
    }
    .popup-modal__footer > p {
      margin: 0;
      width: 100%;
      text-align: center;
      padding: 15px 0;
    }
  }
  &__content {
    margin: auto;
    min-width: 328px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    background: $white;
    box-shadow:
      0px 8px 16px rgba(58, 58, 68, 0.12),
      0px 16px 32px rgba(90, 91, 106, 0.12);
    border-radius: 16px;

    font-family: Univers;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    font-feature-settings:
      'tnum' on,
      'lnum' on,
      'ss01' on;
    color: $gray_3;

    &.small {
      width: 30%;
    }
    &.medium {
      width: 50%;
    }
    &.large {
      width: 80%;
    }
  }

  &__header {
    color: $warning;
    font-family: Cambon;
    font-weight: 500;
    font-size: 24px !important;
    line-height: 36px !important;
    letter-spacing: 0.04em;
    padding-bottom: 19px;
  }

  &__footer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 140%;
    text-transform: uppercase;

    > p {
      margin: 32px 0 0 32px;
      cursor: pointer;
    }

    &_cancel {
      color: $black;
    }

    &_submit {
      font-weight: bold;
      color: $white;
      background: $warning;
      box-shadow:
        0px 4px 8px rgba(58, 58, 68, 0.12),
        0px 8px 16px rgba(90, 91, 106, 0.12);
      border-radius: 100px;
      padding: 6px 16px;
    }
  }
}
