@import '../../css/colors';
.popup-modal.embedded-modal {
  h2 {
    line-height: 114%;
  }
  .popup-modal__header {
    color: var(--primary-color);
    font-size: 14px !important;
    font-family: var(--font-primary);
    padding-bottom: 0;
    text-transform: uppercase;
  }
  h2 {
    font-size: 24px;
    font-family: var(--font-primary);
    font-weight: 600;
  }
}
