@import '../../../../css/colors';

.bp3-slider-label {
  width: 60px;
  color: gray;
}
.bodyfat-wrapper {
  display: flex;
  justify-content: space-around;
  padding: 24px 32px;

  .bp3-slider-handle .bp3-slider-label {
    background-color: var(--blue25) !important;
    border: 1px solid var(--blue50);
  }

  ._view-module__filled___ZXPge div[class*='slider-progress']:nth-of-type(2) {
    background-color: var(--blue50) !important;
  }
}

.bp3-slider-label.active {
  border: 1px solid var(--gold50);
}

.bp3-slider.bp3-vertical {
  height: 300px !important;
}

.bp3-card.bodyfatCard {
  background-color: transparent;
  box-shadow: none;
}
