@font-face {
  font-family: 'HelveticaNeue-Medium';
  src: url('../fonts/HelveticaNeue-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.apple-pay-button {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  appearance: none;
  mozappearance: none;
  webkitappearance: none;
  border: none;
  padding: 10px;
  border-radius: 8px;
  background-color: black;
}

.apple-pay-button:focus {
  outline: 0;
}

.apple-pay-button-light {
  background-color: white;
}

.apple-pay-button-light:hover {
  background-color: #eeeeee;
}

.apple-pay-button-dark {
  background-color: black;
}

.apple-pay-button-dark:hover {
  background-color: #111111;
}
